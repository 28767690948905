const LoginPage = "Ritike Loans | Login";
const ForgotPage = "Ritike Loans | Forgot password";
const RegisterPage = "Ritike Loans | Register";
const ResetPage = "Ritike Loans | Reset Password";
const LogoutPage = "Ritike Loans | Logout ";
const VerifyEmailPage = "Ritike Loans | Logout ";
const LoangPage = "Ritike Loans | Loan history ";
const DashboardPage = "Ritike Loans | Dashboard ";
const ApproveLoanPage = "Ritike Loans | Approve loan ";
const DeclineLoanPage = "Ritike Loans | Decline loan ";
const UsersPage = "Ritike Loans | Users ";
const LoanRequestPage = "Ritike Loans | Loan requests ";

export {
  LoginPage,
  ForgotPage,
  RegisterPage,
  ResetPage,
  LogoutPage,
  VerifyEmailPage,
  DashboardPage,
  LoangPage,
  ApproveLoanPage,
  DeclineLoanPage,
  UsersPage,
  LoanRequestPage,
};
