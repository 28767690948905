import { Stepper, Step, StepLabel, StepButton, makeStyles, useMediaQuery } from "@material-ui/core";

function LoanProcess({ lastElement }) {
    const useStyles = makeStyles(() => ({
        root: {
            "& .MuiStepIcon-active": { color: "#2A4C7E" },
            "& .MuiStepIcon-completed": { color: "green" },
        }
    }));

    const c = useStyles();

    const isMobile = useMediaQuery('(max-width:600px)');

    return (
        <div className="mb-4">
            <Stepper className={`${c.root}  card-border-radius`} activeStep={lastElement?.status === "pending" ? 1 : lastElement?.status === "verification" ? 2 : lastElement?.status === "approve" ? 3 : 0}>
                <Step>
                    <StepButton>{isMobile ? 'Pend' : 'Pending'}</StepButton>
                </Step>
                <Step>
                    <StepLabel>{isMobile ? 'Verify' : 'Verified'}</StepLabel>
                </Step>
                <Step>
                    <StepLabel>{isMobile ? 'Aprvd' : 'Approved'}</StepLabel>
                </Step>
            </Stepper>
        </div>
    );
}

export default LoanProcess;