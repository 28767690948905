import React, { useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import CustomBtn from '../../../components/ui/CustomBtn';
import { loginUser } from '../../../Redux/Slices/userSlice';
import { useStore1Selector } from 'index';
import usePost from '../../../hooks/usePost';
import { AddCostMsg } from '../../../constant/NotifyMessage';

const AddCostForm = ({ setOpenModal, reFetch }) => {

    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;
    const { execute, pending, data } = usePost()

    const [documentUrl, setDocumentUrl] = useState(null);

    const handleChange = (event) => {
        const file = event.target.files[0];
        setDocumentUrl(file);
    };

    const handleValidSubmit = (event, values) => {
        event.preventDefault();

        const Method = 'POST', endPoint = 'costs/costs', isJSON = true;
        const formdata = new FormData();

        formdata.append("name", values.name);
        formdata.append("description", values.description);
        formdata.append("amount", values.amount);
        formdata.append("documentUrl", documentUrl);
        formdata.append("costNumber", values.costNumber);

        execute(endPoint, formdata, Method, AddCostMsg, token, isJSON)
    };

    if (data?.status === 'success') {
        setOpenModal()
        setTimeout(() => {
            reFetch()
        }, 2000)
    }

    return (
        <Container>
            <AvForm className="form-horizontal mt-4" onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}>

                <Row>
                    <Col sm={12} md={6}>

                        <div className="mb-3">
                            <AvField
                                type="select"
                                name="name"
                                label="Cost Name"
                                required
                            >
                                <option value="">Select Cost Name</option>
                                <option value="operation">Operation</option>
                                <option value="sales">Sales</option>
                                <option value="business development">Business Development</option>
                                <option value="investor">Investor</option>
                            </AvField>
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <div className="mb-3">
                            <AvField
                                type="select"
                                name="costNumber"
                                label="Cost Number"
                                required
                            >
                                <option value="">Select Cost Number</option>
                                <option value="CC01">CC01</option>
                                <option value="CC02">CC02</option>
                                <option value="CC03">CC03</option>
                                <option value="CC04">CC04</option>
                            </AvField>
                        </div>
                    </Col>
                    <Col sm={12} md={6}>

                        <div className="mb-3">
                            <AvField
                                type="number"
                                name="amount"
                                label="Amount"
                                required
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={6}>
                        <p className="float-start ">Upload your file</p>
                        <div>
                            <input type="file" className="form-control" name="documentUrl" onChange={handleChange} />
                        </div>

                    </Col>
                    <Col sm={12} md={12}>
                        <div className="mb-3">
                            <AvField
                                type="textarea"
                                name="description"
                                label="Description"
                                required
                            />
                        </div>
                    </Col>
                </Row>

                <CustomBtn Pending={pending} btnName="Submit" />

            </AvForm>
        </Container>
    );
};

export default AddCostForm;
