import React from "react"
import { Container } from "reactstrap";
import Breadcrumb from "../../components/common/Breadcrumb";
import MetaTag from "../../components/common/MetaTagSeo";
import { LoanRequestPage } from "../../constant/SCO_Name";
import { LoanRequestRoute } from "../../constant/RouteName";
import useFetch from '../../hooks/useFetch';
import { ServerError } from "../../constant/NotifyMessage";
import Loading from "../../components/helper/Loading";
import { useStore1Selector } from "index";
import { loginUser } from "../../Redux/Slices/userSlice";
import { BsEyeFill } from "react-icons/bs";
import { Link } from "react-router-dom"
import LoadingAnimation from "../../components/helper/Loading-animation";
import { mandateColumns } from "../../constant/Columns";
import Table from "../../components/common/Table";
import { formatDate } from "../../utils/dateFormat";

const Mandate = () => {

    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;
    const res_data = [];
    const { data, loading, error } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/mandates?sort=-createdAt`, token);

    if (loading) return <LoadingAnimation />
    if (error) return <ErrorPage message={ServerError} />

    const filterArr = () => {
        data.forEach((res, i) => {
            res_data.push({
                ...res,
                firstName: res?.user?.firstName,
                lastName: res?.user?.lastName,
                email: res?.user?.email,
                gracePeriodPaymentDate: formatDate(res?.gracePeriodPaymentDate),
                viewBtn: <button className="btn btn__table  color__blue"> <Link className="text-white" to={`/mondate-form/${res?._id}`}>  <BsEyeFill size={14} /> View </Link> </button>
            })
        });
    }
    filterArr();

    return (
        <React.Fragment>
            <div className="page-content px-5">
                <Breadcrumb default={LoanRequestRoute} defaultName="Mandate form" title={"form"} />
                <MetaTag title_sco={LoanRequestPage} />
                <Container fluid>
                    <div className="page-title-box">
                        {loading ? <Loading /> : (<Table columns={mandateColumns} data={res_data} />)}
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default Mandate