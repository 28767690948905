const DashboardPageDefault = "Dashboard";
const DashboardPageAdmin = "Admin";
const DashboardPageTitle = "Loans";
const ApproveLoanPageTitle = "Approved Loan";
const DeclineLoanPageTitle = "Declined Loan";
const UserTitle = "Users";
const LoansTabsPageDefault = "Loans";

export {
  DashboardPageDefault,
  DashboardPageTitle,
  ApproveLoanPageTitle,
  DeclineLoanPageTitle,
  UserTitle,
  DashboardPageAdmin,
  LoansTabsPageDefault,
};
