import React from 'react';
import { Card, Container } from "reactstrap";
import { useStore1Selector } from "index";
import { IoLinkSharp } from "react-icons/io5";
import { loginUser } from "../../Redux/Slices/userSlice";
import useFetch from "../../hooks/useFetch";
import { Link } from 'react-router-dom';
import { MandateFormLinks } from '../../constant/RouteName';
import LoadingAnimation from '../../components/helper/Loading-animation';
import Breadcrumb from '../../components/common/Breadcrumb';
import { DashboardPageDefault } from '../../constant/BreadCrum';
import ErrorPage from '../../components/helper/ErrorPage';
import { ServerError } from '../../constant/NotifyMessage';
import { formatDate } from "../../utils/dateFormat";

const MandateLists = () => {

    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;
    const userId = userDet?.data?.data?._id;
    const { data, loading, error } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/mandates/status/${userId}`, token);

    if (loading) return <LoadingAnimation />
    if (error) return <ErrorPage message={ServerError} />

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb default={DashboardPageDefault} title="Mandate Lists" />
                <Container fluid>
                    <div className="page-title-box">

                        <div className="mb-4 text-end">
                            Click here to view mandate form <Link to={MandateFormLinks}><IoLinkSharp size={24} /> </Link>
                        </div>

                        {data.map((dataItem, index) => {
                            return (
                                <Card className="p-4" key={index}>
                                    <div className="my-2">
                                        <h4>  <b className="text-primary"> Date sign :  {dataItem?.dateSignedAt.split('T')[0]} </b> </h4>
                                    </div>

                                    <div className="my-2">
                                        <h5>  <b> Payment date :  {dataItem?.loan?.paymentDate.split('T')[0]} </b> </h5>
                                        <h5>  <b> Amount to pay :  {dataItem?.loan?.amount + dataItem?.loan?.totalAmount} </b> </h5>

                                        {dataItem?.loan?.gracePeriodAmount ? <h4 className='my-4'> Grace Period </h4> : null}
                                        {
                                            dataItem?.loan?.gracePeriodAmount ?
                                                <p> <b>Grace Period Amount : {dataItem?.loan?.gracePeriodAmount} </b></p> : null
                                        }
                                        {
                                            dataItem?.loan?.gracePeriodPercentage ?
                                                <p> <b>Grace Period Percentage :  {dataItem?.loan?.gracePeriodPercentage}</b>  </p> : null
                                        }
                                        {
                                            dataItem?.loan?.gracePeriodPaymentDate ?
                                                <p> <b>Grace Period Payment Date : {formatDate(dataItem?.loan?.gracePeriodPaymentDate)}</b> </p> : null
                                        }
                                    </div>

                                    <div className="my-2">
                                        <p>
                                            If however, the date of the payment instruction falls on a non-processing day (weekend or public holiday) I agree that the payment instruction may be debited against my account on the following business day: <b className="text-primary"> {dataItem?.agreement ? "Yes" : "No"} </b>
                                        </p>
                                    </div>
                                    <div className="my-2">
                                        <p>
                                            The date of the instruction falls on a non-processing day (weekend or public holiday) I agree that the payment instruction may be debited against my account on the business day prior to the non-processing day: <b className="text-primary"> {dataItem?.authorized ? "Yes" : "No"} </b>
                                        </p>
                                    </div>
                                    <div className="my-2">
                                        <p>
                                            To allow for tracking of dates to match with flow of Credit at no additional cost to myself:
                                            <b className="text-primary"> {dataItem?.debitedAgree ? "Yes" : "No"} </b>
                                        </p>
                                    </div>
                                    <div className="my-2">
                                        <p>
                                            I authorize the originator to make use of the tracking facility as provided for in the EDO system at no additional cost to myself:  <b className="text-primary"> {dataItem?.debitedAgree2 ? "Yes" : "No"} </b>
                                        </p>
                                    </div>
                                    <div className="my-2">
                                        <p>
                                            Subsequent payment instructions will continue to be delivered in terms of this authority until the obligations in terms of the Agreement have been paid or until this authority is cancelled by me/us by giving you notice in writing: <b className="text-primary"> {dataItem?.trackingOfDate ? "Yes" : "No"} </b>
                                        </p>
                                    </div>
                                </Card>
                            )
                        })}

                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default MandateLists