import React, { useState } from "react"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"
import { Link } from "react-router-dom"
import user1 from "../../src/assets/images/users/user-4.jpg"
import { useStore1Selector } from "../index";
import { loginUser } from "../Redux/Slices/userSlice";

const ProfileMenu = () => {

  const [menu, setMenu] = useState(false)
  const userDet = useStore1Selector(loginUser)
  const imagePic = userDet?.data?.data?.photoProfile;

  return (
    <React.Fragment>
      <Dropdown isOpen={menu} toggle={() => setMenu(!menu)} className="d-inline-block">
        <DropdownToggle className="btn header-item waves-effect profile__btn" id="page-header-user-dropdown" tag="button">
          <img
            className="rounded-circle header-profile-user"
            src={!imagePic ? user1 : imagePic}
            alt="user"
          />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">

          <Link to="/my-profile">
            <DropdownItem tag="a">
              <i className="bx bx-user font-size-16 align-middle me-1" />
              Profile
            </DropdownItem>
          </Link>

          <div className="dropdown-divider" />

          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Logout</span>
          </Link>

        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}


export default ProfileMenu