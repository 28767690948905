import {
  ActionRequiredRoute,
  AdminDashboardRoute,
  AdminRegisterUserRoute,
  ApproveLoanRoute,
  BlackListMng,
  CostRoute,
  CustomerDashboardRoute,
  DashboardRoute,
  DebtCollectionRoute,
  DebtCollectorsMngt,
  DeclineLoanRoute,
  FinancialStatmentMngt,
  ForgotPasswordRoute,
  GracePeriodRoute,
  LoansTabsRoute,
  LoginRoute,
  LogoutRoute,
  MandateFormLinks,
  MandateUserRoute,
  MondateApplicationRoute,
  MondateFormroute,
  MondateFormrouteID,
  PaidLoansMngt,
  PreAgreementRoute,
  ProfileRoute,
  RegisterRoute,
  ResetPasswordRoute,
  TeamRoute,
  UsersRoute,
  VerifyEmailRoute
} from "constant/RouteName";
import { Redirect } from "react-router-dom";

//* ********************Authentication pages********************
import AdminAddUserRegister from "pages/Authentication/AdminAddUser";
import ForgetPwd from "pages/Authentication/ForgetPassword";
import Login from "pages/Authentication/Login";
import Logout from "pages/Authentication/Logout";
import Register from "pages/Authentication/Register";
import ResetPassword from "pages/Authentication/ResetPassword";
import VerifiedEmail from "pages/Authentication/VerifiedEmail";
//* ********************end********************


//* ********************Users pages********************
import Profile from "pages/common/index";
import ApproveLoan from "pages/Users/approveLoans";
import CustomerDashboard from "pages/Users/dashboard";
import DeclineLoan from "pages/Users/declineLoans";
import MondateApplication from "pages/Users/mandateForm";
import MondatePageLinks from "pages/Users/mandateLinks";
import UserMandate from "pages/Users/mandateLists";
//* ********************End********************


//* ********************Admin pages********************
import ActionRequired from "pages/Admin/actionRequired";
import AdminCosts from "pages/Admin/costs";
import Users from "pages/Admin/customers";
import AdminDashboard from "pages/Admin/dashboard";
import AdminDebtCollection from "pages/Admin/debtCollection.js";
import GracePeriod from "pages/Admin/grace-period";
import LoansTabs from "pages/Admin/loans";
import MondatePage from "pages/Admin/mandate";
import Details from "pages/Admin/mandateDetails";
import PreAgreement from "pages/Admin/pre-agreement";
import Teams from "pages/Admin/teams";
//* ********************End********************


//* ************************Management pages************************
import BlackList from "pages/Management/blackListed";
import DebCollectors from "pages/Management/debtCollectors";
import FinancialStatement from "pages/Management/financialStatement";
import PaidLoans from "pages/Management/paidLoans";
//* ************************end ************************

const userRoutes = [
  { path: AdminDashboardRoute, component: AdminDashboard },
  { path: CustomerDashboardRoute, component: CustomerDashboard },
  { path: ApproveLoanRoute, component: ApproveLoan },
  { path: DeclineLoanRoute, component: DeclineLoan },
  { path: UsersRoute, component: Users },
  { path: MondateFormroute, component: MondatePage },
  { path: MandateFormLinks, component: MondatePageLinks },
  { path: LoansTabsRoute, component: LoansTabs },

  { path: MondateFormrouteID, component: Details },
  { path: MondateApplicationRoute, component: MondateApplication },
  { path: TeamRoute, component: Teams },
  { path: ProfileRoute, component: Profile },
  { path: CostRoute, component: AdminCosts },
  { path: GracePeriodRoute, component: GracePeriod },
  { path: PreAgreementRoute, component: PreAgreement },

  //* New update
  { path: DebtCollectionRoute, component: AdminDebtCollection },
  { path: MandateUserRoute, component: UserMandate },
  { path: ActionRequiredRoute, component: ActionRequired },

  //* User Management
  { path: DebtCollectorsMngt, component: DebCollectors },
  { path: PaidLoansMngt, component: PaidLoans },
  { path: BlackListMng, component: BlackList },
  { path: FinancialStatmentMngt, component: FinancialStatement },

  { path: "/", exact: true, component: () => <Redirect to={DashboardRoute} /> },
];

const authRoutes = [
  { path: LoginRoute, exact: true, component: Login },
  { path: RegisterRoute, exact: true, component: Register },
  {
    path: AdminRegisterUserRoute,
    exact: true,
    component: AdminAddUserRegister,
  },
  { path: ForgotPasswordRoute, exact: true, component: ForgetPwd },
  { path: ResetPasswordRoute, exact: true, component: ResetPassword },
  { path: VerifyEmailRoute, exact: true, component: VerifiedEmail },
  { path: LogoutRoute, exact: true, component: Logout },
];

export { authRoutes, userRoutes };

