import React from "react"
import useFetch from '../../../hooks/useFetch';
import { ServerError } from "../../../constant/NotifyMessage";
import Loading from "../../../components/helper/Loading";
import { useStore1Selector } from "index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import { BsEyeFill } from "react-icons/bs";
import ModalComp from '../../../components/common/Modal';
import ErrorPage from "../../../components/helper/ErrorPage";
import LoadingAnimation from "../../../components/helper/Loading-animation";
import Table from "../../../components/common/Table";
import { approvedLoansColumns } from "../../../constant/Columns";
import ViewLoan from "../../../components/views/ViewLoan";
import { formatDate } from "../../../utils/dateFormat";
import SmallModal from "../../../components/common/SmallModal";
import Modal from "../../../sections/Admin/action-required/UpdatePaidAmount";

const ApprovedLoans = () => {

    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;
    const res_data = [];
    const { data, loading, error, reFetch } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/loans?status=approve&sort=-createdAt`, token);
    const [loanId, setLoanId] = React.useState();
    const [viewUserDet, setViewUserDet] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [status, setStatus] = React.useState();
    const [btnName, setBtnName] = React.useState();

    if (loading) return <LoadingAnimation />
    if (error) return <ErrorPage message={ServerError} />

    const viewDetails = (id) => {
        setViewUserDet(true)
        setLoanId(id)
    }

    const updatePayment = (id) => {
        setOpenModal(true)
        setStatus("paid")
        setLoanId(id)
        setBtnName("Submit")
    }

    const filterArr = () => {
        data?.forEach(res => {
            res_data.push({
                ...res,
                firstName: res?.user?.firstName,
                lastName: res?.user?.lastName,
                payment_Date: formatDate(res?.paymentDate),
                image: <img src={`${process.env.REACT_APP_IMG_API}${res?.user?.photoProfile}`} alt="" width={50} height={40} />,
                viewBtn: <button className="btn btn__table  color__blue" onClick={() => viewDetails(res?._id)}> <BsEyeFill size={14} /> View </button>,
                paidBtn: <button className="btn btn__table color__green" onClick={() => updatePayment(res?._id)}>  Paid </button>,
            })
        });
    }
    filterArr();

    return (
        <React.Fragment>
            {loading ? <Loading /> : (<Table columns={approvedLoansColumns} data={res_data} />)}

            <ModalComp
                ModalTitle=""
                open={viewUserDet}
                onClose={() => setViewUserDet(false)}
                cancel="close"
                Component={<ViewLoan onClose={() => setViewUserDet(false)} loan_Id={loanId} reFetch={reFetch} />}
            />

            {/* View Paid modal */}
            <SmallModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                ModalTitle=""
                cancel="close"
                Components={<Modal
                    reFetch={reFetch}
                    onClose={() => setOpenModal(false)}
                    status={status}
                    loanId={loanId}
                    btnName={btnName}
                />}
            />
        </React.Fragment>
    )
}
export default ApprovedLoans