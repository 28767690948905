import { Col, Row } from 'reactstrap'

function LoanFeeBreakdown() {

    return (
        <Row>
            <Col md={12}>
                <div className="loan-fee-breakdown p-4 bg-light border rounded">
                    <h2 className="text-center mb-4">Loan Fee Breakdown</h2>

                    <div className="fee-item mb-3">
                        <h4 className="fw-bold">Initiation Fee</h4>
                        <p>
                            <strong>For Loan Amounts Less Than or Equal to R1000:</strong> A flat initiation fee of 15% of the loan amount is applied.
                        </p>
                        <p>
                            <strong>For Loan Amounts Greater Than R1000:</strong> The first R1000 is charged at 15%, and any amount exceeding R1000 is charged at 10%.
                        </p>
                    </div>

                    <div className="fee-item mb-3">
                        <h4 className="fw-bold">Service Fee</h4>
                        <p>
                            A fixed service fee of <strong>R2 per day</strong> is added for each day of the loan period.
                        </p>
                    </div>

                    <div className="fee-item mb-3">
                        <h4 className="fw-bold">Interest Fee</h4>
                        <p>
                            The interest on your loan is calculated at a rate of <strong>0.1% per day</strong>, multiplied by the number of days you choose.
                        </p>
                    </div>

                    <div className="fee-summary mt-4">
                        <h3 className="text-center text-primary">Total Fees = Initiation Fee + Service Fee + Interest Fee</h3>
                        <p className="text-center">This will be added to the loan amount to determine the total amount you need to pay back.</p>
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default LoanFeeBreakdown