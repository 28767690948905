import ErrorPage from 'components/helper/ErrorPage';
import LoadingAnimation from 'components/helper/Loading-animation';
import { ServerError } from 'constant/NotifyMessage';
import useFetch from 'hooks/useFetch';
import { useStore1Selector } from 'index';
import React from 'react';
import { Container, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { loginUser } from 'Redux/Slices/userSlice';
import { formatDate } from 'utils/dateFormat';

function ViewCost({ costId }) {

    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;
    const { data: cost, loading, error } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/costs/costs/${costId}`, token);

    if (loading) return <LoadingAnimation />
    if (error) return <ErrorPage message={ServerError} />

    return (
        <Container>
            <Card>
                <CardBody>
                    <CardTitle tag="h5">Cost Name : {cost.name}</CardTitle>
                    <CardText><strong>Cost Number:</strong> {cost.costNumber}</CardText>
                    <CardText><strong>Amount:</strong> R {cost.amount}</CardText>
                    <CardText><strong>Created At:</strong> {formatDate(cost.createdAt)}</CardText>
                    <CardText><strong>Description:</strong> {cost.description}</CardText>

                    <CardText>
                        <strong>Cost Proof:</strong>
                        <embed src={cost.documentUrl} type="application/pdf" width="100%" height="400px" />
                    </CardText>

                </CardBody>
            </Card>
        </Container>
    );
}

export default ViewCost;
