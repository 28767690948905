import React, { useState } from "react"
import { Container } from "reactstrap";
import Breadcrumb from "../../components/common/Breadcrumb";
import MetaTag from "../../components/common/MetaTagSeo";
import { UsersPage } from "../../constant/SCO_Name";
import { UserTitle } from "../../constant/BreadCrum";
import { UsersRoute } from "../../constant/RouteName";
import useFetch from '../../hooks/useFetch';
import { ServerError } from "../../constant/NotifyMessage";
import Loading from "../../components/helper/Loading";
import { useStore1Selector } from "index";
import { loginUser } from "../../Redux/Slices/userSlice";
import { BsEyeFill } from "react-icons/bs";
import ModalComp from '../../components/common/Modal';
import ErrorPage from "../../components/helper/ErrorPage";
import LoadingAnimation from "../../components/helper/Loading-animation";
import ViewCost from "../../components/views/ViewCost";
import AddCostForm from "../../sections/Admin/costs/AddCostForm";
import { formatDate } from "../../utils/dateFormat";
import Table from "../../components/common/Table";
import { costColumns } from "../../constant/Columns";


const Costs = () => {

    const [openModal, setOpenModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [costId, setCostId] = useState();
    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;
    const res_data = [];

    const { data, loading, error, reFetch } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/costs/costs?sort=-createdAt`, token);

    const viewDetails = (id) => {
        setOpenViewModal(true)
        setCostId(id)
    }

    if (loading) return <LoadingAnimation />
    if (error) return <ErrorPage message={ServerError} />

    const filterArr = () => {
        data?.forEach(res => {
            res_data.push({
                ...res,
                viewBtn: <button className="btn btn__table  color__blue" onClick={() => viewDetails(res?._id)}> <BsEyeFill size={14} /> View </button>,
                dateCreated: formatDate(res?.createdAt),
            })
        });
    }
    filterArr();

    return (
        <React.Fragment>
            <div className="page-content px-5">
                <Breadcrumb default={UsersRoute} defaultName="Users" title={UserTitle} />
                <MetaTag title_sco={UsersPage} />

                <Container fluid>
                    <button className="btn color__blue" onClick={() => setOpenModal(true)}>  + add cost   </button>
                    <div className="page-title-box">
                        {loading ? <Loading /> : (<Table columns={costColumns} data={res_data} />)}
                    </div>
                </Container>
            </div>

            {/* View Costs */}
            <ModalComp
                ModalTitle=""
                open={openViewModal}
                onClose={() => setOpenViewModal(false)}
                cancel="close"
                Component={<ViewCost costId={costId} />}
            />

            {/* Add new costs */}
            <ModalComp
                ModalTitle=""
                open={openModal}
                onClose={() => setOpenModal(false)}
                cancel="close"
                Component={<AddCostForm setOpenModal={setOpenModal} reFetch={reFetch} />}
            />

        </React.Fragment>
    )
}
export default Costs