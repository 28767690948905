import {
  DebtCollectorsMngt,
  BlackListMng,
  FinancialStatmentMngt,
  PaidLoansMngt,
} from "constant/RouteName";
import React from "react";
import { Link } from "react-router-dom";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { GiReceiveMoney } from "react-icons/gi";
import { GiMoneyStack } from "react-icons/gi";
import { TbLockOff } from "react-icons/tb";
import { useStore1Selector } from "index";
import { loginUser } from "Redux/Slices/userSlice";
import useFetch from "hooks/useFetch";

function userManagementSidebarLinks() {

  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;

  const { data: debt } = useFetch(
    `${process.env.REACT_APP_BACKEND_URL}/loans/management/debt-collectors?debtCollector=true&debtCollectorPaymentStatus=false&blackListed=false&sort=-createdAt`,
    token
  );
  const { data: blackList } = useFetch(
    `${process.env.REACT_APP_BACKEND_URL}/loans/management/debt-collectors?blackListed=true&status=blacklisted&sort=-createdAt`,
    token
  );

  return (
    <>
      <li className="menu-title">Manager</li>
      <li>
        <Link to={FinancialStatmentMngt} className="waves-effect">
          <RiMoneyDollarCircleFill /> <span>Financial Statement</span>
        </Link>
      </li>
      <li>
        <Link to={DebtCollectorsMngt} className="waves-effect">
          <span className="badge rounded-pill bg-warning float-end">{debt?.length}</span>
          <GiMoneyStack /> <span>Debt Collectors</span>
        </Link>
      </li>
      <li>
        <Link to={PaidLoansMngt} className="waves-effect">
          <GiReceiveMoney /> <span>Paid Loans</span>
        </Link>
      </li>
      <li>
        <Link to={BlackListMng} className="waves-effect">
          <span className="badge rounded-pill bg-danger float-end">{blackList?.length}</span>
          <TbLockOff /> <span> BlackList </span>
        </Link>
      </li>
    </>
  );
}

export default userManagementSidebarLinks;
