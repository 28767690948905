import React from "react"
import "./Style.scss";
import { Row, Spinner } from "reactstrap";
import { useHistory } from "react-router-dom";
import { AvForm } from "availity-reactstrap-validation"
import MetaTagComp from "../../components/common/MetaTagSeo";
import { RegisterPage } from "../../constant/SCO_Name";
import FromWraper from "./components/FromWraper";
import usePost from "../../hooks/usePost";
import { RegisterMsg } from "../../constant/NotifyMessage";
import InputForm from "./admin-register-user/InputForm";

const AdminAddUser = () => {
    const history = useHistory()

    const [photoPassport, setPhotoPassport] = React.useState(null);
    const { execute, pending, data } = usePost()

    const handleValidSubmit = (e, values) => {
        e.preventDefault();
        const Method = 'POST', endPoint = 'users/signUp', isJSON = true, token = null;

        const formdata = new FormData();
        formdata.append("firstName", values.firstName);
        formdata.append("lastName", values.lastName);
        formdata.append("phoneNumber", values.phoneNumber);
        formdata.append("gender", values.gender);
        formdata.append("email", values.email);
        formdata.append("address", values.address);
        formdata.append("companyName", values.companyName);
        formdata.append("jobTitle", values.jobTitle);
        formdata.append("province", values.province);
        formdata.append("city", values.city);
        formdata.append("postalCode", values.postalCode);
        formdata.append("referralCode", values.referralCode);
        formdata.append("accountNumber", values.accountNumber);
        formdata.append("bankName", values.bankName);
        formdata.append("accountType", values.accountType);
        formdata.append("agreed", true);
        formdata.append("blackListed", false);
        formdata.append("passportPhoto", photoPassport);
        formdata.append("password", values.password);
        formdata.append("passwordConfirm", values.passwordConfirm);
        formdata.append("idNumber", values.idNumber);
        execute(endPoint, formdata, Method, RegisterMsg, token, isJSON)
    }

    if (data?.status === 'success') {
        history.push("/login");
    }

    return (
        <React.Fragment>
            <MetaTagComp title_sco={RegisterPage} />
            <Row className="fixed_height">
                <FromWraper title="SignUp" size={12} >
                    <AvForm className="mt-4" onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}>
                        <InputForm setPhotoPassport={setPhotoPassport} />

                        <div className="text-center">
                            <button className="btn custom-btn  text-white " type="submit">
                                {!pending ? <span className="me-2"> Submit </span> : <span> <Spinner as="span" animation="border" size="sm" /> Loading...</span>}
                            </button>
                        </div>

                    </AvForm>
                </FromWraper>
            </Row>
        </React.Fragment>
    )
}

export default AdminAddUser