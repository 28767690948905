import { AvCheckbox, AvCheckboxGroup, AvForm } from "availity-reactstrap-validation";
import useFetch from 'hooks/useFetch';
import { useStore1Selector } from "index";
import React, { useRef, useState } from 'react';
import { BsArrowLeft } from 'react-icons/bs';
import { Link, useHistory, useParams } from "react-router-dom";
import SignaturePad from 'react-signature-canvas';
import { Card, CardBody, Container } from "reactstrap";
import CustomerPreeAgreement from "sections/Admin/pre-agreement/CustomerPreeAgreement";
import Breadcrumb from "../../components/common/Breadcrumb";
import MetaTag from "../../components/common/MetaTagSeo";
import CustomBtn from "../../components/ui/CustomBtn";
import { DashboardPageDefault } from "../../constant/BreadCrum";
import { FormCompletedMsg } from "../../constant/NotifyMessage";
import { MandateFormLinks } from '../../constant/RouteName';
import { DashboardPage } from "../../constant/SCO_Name";
import usePost from "../../hooks/usePost";
import { loginUser } from "../../Redux/Slices/userSlice";
import { formatDate } from "../../utils/dateFormat";


const MandateForm = () => {
    const { id } = useParams()
    const history = useHistory()

    const { execute, pending, data } = usePost()
    const [signature, setSignature] = useState(null);
    const signaturePad = useRef(null);
    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;
    const { data: getMandateDet } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/mandates/${id}`, token);

    const handleValidSubmit = (e, values) => {
        e.preventDefault();

        if (!signature) {
            alert('Please sign the form');
            return;
        }

        const Method = 'PATCH', endPoint = `mandates/${id}`;
        var raw = JSON.stringify({
            debitedAgree: values.debitedAgree[0],
            debitedAgree2: values.debitedAgree2[0],
            trackingOfDate: values.trackingOfDate[0],
            authorized: values.authorized[0],
            agreement: values.agreement[0],
            signatureData: signature
        });
        execute(endPoint, raw, Method, FormCompletedMsg, token)
    }

    if (data?.status === 'success') {
        window.setTimeout(() => {
            history.push("/mandate-forms");
        }, 2000);
    }

    const handleClear = () => {
        signaturePad.current.clear();
        setSignature(null);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb default={DashboardPageDefault} title="Mandate form" />
                <MetaTag title_sco={DashboardPage} />

                <Container fluid>
                    <div className="page-title-box">
                        <div className="mb-3">
                            <Link to={MandateFormLinks} > <BsArrowLeft /> Back </Link>
                        </div>
                        <Card>
                            <CardBody className="px-5">

                                <CustomerPreeAgreement id={id} />

                                <AvForm className="form-horizontal" onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}>
                                    <div className="my-2 px-2">
                                        <p>
                                            If however, the date of the payment instruction falls on a non-processing day (weekend or public holiday) I agree that the payment instruction may be debited against my account on the following business day.
                                        </p>
                                        <AvCheckboxGroup inline name="debitedAgree" required >
                                            <AvCheckbox customInput label="I AGREE" className="me-3 custom-size-checkbox" value={true} />
                                        </AvCheckboxGroup>
                                    </div>
                                    <div className="my-2 px-2">
                                        <p>
                                            The date of the instruction falls on a non-processing day (weekend or public holiday) I agree that the payment instruction may be debited against my account on the business day prior to the non-processing day.
                                        </p>
                                        <AvCheckboxGroup inline name="debitedAgree2" required>
                                            <AvCheckbox customInput label="I AGREE" className="me-3 bg-white custom-size-checkbox" value={true} />
                                        </AvCheckboxGroup>
                                    </div>
                                    <div className="my-2 px-2">
                                        <p>
                                            To allow for tracking of dates to match with flow of Credit at no additional cost to myself.
                                        </p>
                                        <AvCheckboxGroup inline name="trackingOfDate" required>
                                            <AvCheckbox customInput label="I AGREE" className="me-3 bg-white custom-size-checkbox" value={true} />
                                        </AvCheckboxGroup>
                                    </div>
                                    <div className="my-2 px-2">
                                        <p>
                                            I authorize the originator to make use of the tracking facility as provided for in the EDO system at no additional cost to myself.
                                        </p>
                                        <AvCheckboxGroup inline name="authorized" required>
                                            <AvCheckbox customInput label="I AGREE" className="me-3 bg-white custom-size-checkbox" value={true} />
                                        </AvCheckboxGroup>
                                    </div>
                                    <div className="my-2 px-2">
                                        <p>
                                            It is agreed between me and Ritike Loans (PTY) Ltd that the total amount mentioned above will be paid on the date specified on the application form
                                        </p>
                                        <AvCheckboxGroup inline name="agreement" required>
                                            <AvCheckbox customInput label="I AGREE" className="me-3 bg-white custom-size-checkbox" value={true} />
                                        </AvCheckboxGroup>
                                    </div>

                                    <div className="my-2">
                                        {getMandateDet?.loan?.gracePeriodAmount ? <h4 className='my-4'> Grace Period </h4> : null}
                                        {
                                            getMandateDet?.loan?.gracePeriodAmount ?
                                                <p> <b>Grace Period Amount : {getMandateDet?.loan?.gracePeriodAmount} </b></p> : null
                                        }
                                        {
                                            getMandateDet?.loan?.gracePeriodPercentage ?
                                                <p> <b>Grace Period Percentage :  {getMandateDet?.loan?.gracePeriodPercentage}</b>  </p> : null
                                        }
                                        {
                                            getMandateDet?.loan?.gracePeriodPaymentDate ?
                                                <p> <b>Grace Period Payment Date : {formatDate(getMandateDet?.loan?.gracePeriodPaymentDate)}</b> </p> : null
                                        }
                                    </div>





                                    <div>
                                        <h5>Sign here</h5>
                                        <div className="signature__container">
                                            <SignaturePad
                                                ref={signaturePad}
                                                canvasProps={{ className: 'signature-pad' }}
                                                onEnd={() => {
                                                    const dataURL = signaturePad.current.toDataURL();
                                                    setSignature(dataURL);
                                                }}
                                            />
                                        </div>
                                        <div className="d-sm-flex justify-content-between">
                                            <div>
                                                <button className='btn text-white' onClick={handleClear}>Clear</button>
                                            </div>
                                            <div>
                                                <CustomBtn Pending={pending} btnName="Submit" />
                                            </div>
                                        </div>
                                        {signature && <img src={signature} alt="Signature" />}
                                    </div>


                                </AvForm>

                            </CardBody>
                        </Card>

                    </div>
                </Container>

            </div>
        </React.Fragment>
    )
}

export default MandateForm