import React, { useState } from "react";
import { Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import Breadcrumb from "../../components/common/Breadcrumb";
import { LoansTabsRoute } from "../../constant/RouteName";
import { DashboardPageAdmin, LoansTabsPageDefault } from "../../constant/BreadCrum";
import classnames from "classnames";
import { MdOutlineRequestQuote } from "react-icons/md";
import { GiPayMoney, GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";
import NewRequest from "../../sections/Admin/loan-request/NewRequest";
import ApprovedLoans from "../../sections/Admin/loan-request/ApprovedLoans";
import DeclinedLoans from "../../sections/Admin/loan-request/DeclinedLoans";
import PaidLoans from "../../sections/Admin/loan-request/PaidLoans";

const tabsData = [
  { id: "1", icon: <MdOutlineRequestQuote size={20} />, text: "New requests", content: <NewRequest /> },
  { id: "2", icon: <GiReceiveMoney size={20} />, text: "Approved loans", content: <ApprovedLoans /> },
  { id: "3", icon: <GiPayMoney size={20} />, text: "Declined loans", content: <DeclinedLoans /> },
  { id: "4", icon: <GiTakeMyMoney size={20} />, text: "Paid loans", content: <PaidLoans /> }
];

const Loans = () => {
  const [activeTabJustify, setActiveTabJustify] = useState("1");

  const tabHandler = (tab) => {
    if (activeTabJustify !== tab) {
      setActiveTabJustify(tab);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content px-5">
        <Breadcrumb default={LoansTabsRoute} defaultName={LoansTabsPageDefault} title={DashboardPageAdmin} />
        <Container fluid>
          <Row>
            <Col lg={12}>
              <Card className="card-border-radius">
                <CardBody>
                  <Nav tabs justified className="nav-tabs-custom">
                    {tabsData.map(tab => (
                      <NavItem key={tab.id}>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({ active: activeTabJustify === tab.id })}
                          onClick={() => tabHandler(tab.id)}
                        >
                          <span className="d-none d-sm-block">
                            {tab.icon} {tab.text}
                          </span>
                        </NavLink>
                      </NavItem>
                    ))}
                  </Nav>
                </CardBody>
                <TabContent activeTab={activeTabJustify} className="text-muted">
                  {tabsData.map(tab => (
                    <TabPane key={tab.id} tabId={tab.id}>
                      <Row>
                        <Col sm="12">
                          {tab.content}
                        </Col>
                      </Row>
                    </TabPane>
                  ))}
                </TabContent>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Loans;
