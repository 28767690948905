import ModalComp from "components/common/Modal";
import { useStore1Selector } from "index";
import React from "react";
import { BsEyeFill } from "react-icons/bs";
import { Container } from "reactstrap";
import PreAgreementDetails from "sections/Admin/pre-agreement/PreAgreement";
import Breadcrumb from "../../components/common/Breadcrumb";
import MetaTag from "../../components/common/MetaTagSeo";
import Table from "../../components/common/Table";
import Loading from "../../components/helper/Loading";
import LoadingAnimation from "../../components/helper/Loading-animation";
import { preAgreementColumns } from "../../constant/Columns";
import { ServerError } from "../../constant/NotifyMessage";
import { LoanRequestRoute } from "../../constant/RouteName";
import { LoanRequestPage } from "../../constant/SCO_Name";
import useFetch from '../../hooks/useFetch';
import { loginUser } from "../../Redux/Slices/userSlice";
import { formatDate } from "../../utils/dateFormat";

const PreAgreement = () => {

    const [loanId, setLoanId] = React.useState();
    const [viewUserDet, setViewUserDet] = React.useState(false);
    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;
    const res_data = [];
    const { data, loading, error } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/mandates?sort=-createdAt`, token);

    if (loading) return <LoadingAnimation />
    if (error) return <ErrorPage message={ServerError} />

    const filterArr = () => {
        data.forEach((res, i) => {
            res_data.push({
                ...res,
                firstName: res?.user?.firstName,
                lastName: res?.user?.lastName,
                email: res?.user?.email,
                gracePeriodPaymentDate: formatDate(res?.gracePeriodPaymentDate),
                viewBtn: <button
                    onClick={() =>
                        viewDetails(res._id)
                    }
                    className="btn btn__table  color__blue">
                    <BsEyeFill size={14} /> View
                </button>
            })
        });
    }
    filterArr();

    const viewDetails = (id) => {
        setViewUserDet(true)
        setLoanId(id)
    }

    return (
        <React.Fragment>
            <div className="page-content px-5">
                <Breadcrumb default={LoanRequestRoute} defaultName="Pre Agreement" title={"form"} />
                <MetaTag title_sco={LoanRequestPage} />
                <Container fluid>
                    <div className="page-title-box">
                        {loading ? <Loading /> : (<Table columns={preAgreementColumns} data={res_data} />)}
                    </div>
                </Container>

                <ModalComp
                    ModalTitle=""
                    open={viewUserDet}
                    onClose={() => setViewUserDet(false)}
                    cancel="close"
                    Component={<PreAgreementDetails id={loanId} />}
                />
            </div>
        </React.Fragment>
    )
}
export default PreAgreement