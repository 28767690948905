import React from "react";
import { Container } from "reactstrap";
import useFetch from "../../../hooks/useFetch";
import { ServerError } from "../../../constant/NotifyMessage";
import Loading from "../../../components/helper/Loading";
import { useStore1Selector } from "index";
import { loginUser } from "../../../Redux/Slices/userSlice";
import { BsEyeFill } from "react-icons/bs";
import ModalComp from "../../../components/common/Modal";
import ErrorPage from "../../../components/helper/ErrorPage";
import LoadingAnimation from "../../../components/helper/Loading-animation";
import Table from "../../../components/common/Table";
import ViewLoan from "../../../components/views/ViewLoan";
import { paidLoansColumns } from "../../../constant/Columns";
import { formatDate } from "../../../utils/dateFormat";

const PaidLoans = () => {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;
  const res_data = [];
  const { data, loading, error } = useFetch(
    `${process.env.REACT_APP_BACKEND_URL}/loans?status=paid&sort=-createdAt`,
    token
  );

  const [loanId, setLoanId] = React.useState();
  const [viewUserDet, setViewUserDet] = React.useState(false);

  if (loading) return <LoadingAnimation />;
  if (error) return <ErrorPage message={ServerError} />;

  const viewDetails = (id) => {
    setViewUserDet(true);
    setLoanId(id);
  };

  const filterArr = () => {
    data?.forEach((res) => {
      res_data.push({
        ...res,
        firstName: res?.user?.firstName,
        lastName: res?.user?.lastName,
        payment_Date: formatDate(res?.paymentDate),
        image: (
          <img
            src={`${process.env.REACT_APP_IMG_API}${res?.user?.photoProfile}`}
            alt=""
            width={50}
            height={40}
          />
        ),
        viewBtn: (
          <button
            className="btn btn__table  color__blue"
            onClick={() => viewDetails(res?._id)}
          >
            <BsEyeFill size={14} /> View
          </button>
        ),
      });
    });
  };
  filterArr();

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <div className="page-title-box">
            {loading ? <Loading /> : <Table columns={paidLoansColumns} data={res_data} />}
          </div>
        </Container>
      </div>

      <ModalComp
        ModalTitle=""
        open={viewUserDet}
        onClose={() => setViewUserDet(false)}
        cancel="close"
        Component={
          <ViewLoan onClose={() => setViewUserDet(false)} loan_Id={loanId} />
        }
      />
    </React.Fragment>
  );
};
export default PaidLoans;
