import React from 'react'
import { AvField, } from "availity-reactstrap-validation"
import { Row, Col } from "reactstrap"

function InputForm() {

    const handlePhotoPassport = (e) => {
        setPhotoPassport(e.target.files[0])
    }

    return (
        <>
            {/* Part one */}
            <div>
                <h5 className="card-title section__header my-4">Personal details</h5>
                <Row>
                    <Col md={4}>
                        <div className="mb-3">
                            <AvField
                                name="firstName"
                                label="First Name"
                                className="form-control"
                                placeholder="Enter your first name"
                                type="text"
                                required
                            />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <AvField
                                name="lastName"
                                label="Last Name"
                                className="form-control"
                                placeholder="Enter your last name"
                                type="text"
                                required
                            />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <AvField
                                name="phoneNumber"
                                label="Phone Number"
                                className="form-control"
                                placeholder="phone number"
                                type="text"
                                required
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={4}>
                        <div className="mb-3">
                            <AvField
                                name="email"
                                label="Email"
                                className="form-control"
                                placeholder="Enter your email"
                                type="email"
                                required
                            />
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className="mb-3">
                            <AvField
                                name="gender"
                                label="Gender"
                                className="form-control"
                                type="select"
                                required
                            >
                                <option> Select... </option>
                                <option> Male </option>
                                <option> Female </option>
                                <option> Others </option>

                            </AvField>
                        </div>
                    </Col>

                    <Col md={4}>
                        <div className="mb-3">
                            <AvField
                                name="address"
                                label="Street Address"
                                className="form-control"
                                placeholder="Street address"
                                type="text"
                                required
                            />
                        </div>
                    </Col>
                </Row>

                <Col md={12}>
                    <div className="mb-3">
                        <AvField
                            name="idNumber"
                            label="ID Number"
                            className="form-control"
                            placeholder="Enter your ID Number"
                            type="text"
                            required
                        />
                    </div>
                </Col>
            </div>

            {/* Part two */}
            <div>
                <h5 className="card-title section__header my-4"> Account Setup </h5>
                <Row>
                    <Col md={4}>
                        <div className="mb-3">
                            <AvField
                                name="passportPhoto"
                                label="Upload copy of ID, any document file (Not required)"
                                className="form-control"
                                type="file"
                                onChange={handlePhotoPassport}
                            />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <AvField
                                name="password"
                                label="Password"
                                className="form-control"
                                placeholder="Enter your password"
                                type="password"
                                required
                            />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <AvField
                                name="passwordConfirm"
                                label="Confirm Password"
                                className="form-control"
                                placeholder="Re enter your password"
                                type="password"
                                required
                            />
                        </div>
                    </Col>
                </Row>
            </div>

            {/* Part three */}
            <div>
                <h5 className="card-title section__header my-4"> Location and Employment </h5>

                <Row>
                    <Col md={4}>
                        <div className="mb-3">
                            <AvField
                                name="province"
                                label="Province"
                                className="form-control"
                                placeholder="Enter province"
                                type="text"
                                required
                            />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <AvField
                                name="city"
                                label="City"
                                className="form-control"
                                placeholder="Enter city"
                                type="text"
                                required
                            />
                        </div>
                    </Col>
                    <Col md={4}>
                        <Row>
                            <Col md={12}>
                                <div className="mb-3">
                                    <AvField
                                        name="postalCode"
                                        label="Postal Code"
                                        className="form-control"
                                        placeholder="Enter postal code"
                                        type="number"
                                        required
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row>
                    <Col md={4}>
                        <div className="mb-3">
                            <AvField
                                name="referralCode"
                                label="Referral Code"
                                className="form-control"
                                placeholder="Enter referral code"
                                type="number"
                            />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <AvField
                                name="companyName"
                                label="Company Name"
                                type="text"
                                placeholder="Enter your company name"
                                required
                            />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <AvField
                                name="jobTitle"
                                label="Job Title"
                                type="text"
                                placeholder="Enter your job title"
                                required
                            />
                        </div>
                    </Col>
                </Row>


                <h5 className="card-title section__header my-4"> Banking details </h5>
                <Row>
                    <Col md={4}>
                        <div className="mb-3">
                            <AvField
                                name="accountNumber"
                                label="Account Number"
                                className="form-control"
                                placeholder="Enter account number"
                                type="number"
                                required
                            />
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <AvField name="bankName" label="Bank Name" type="select" required>
                                <option> Select...</option>
                                <option> FNB</option>
                                <option> ABSA</option>
                                <option> CAPITEC</option>
                                <option> NEDBANK</option>
                                <option> STANDARD BANK</option>
                                <option> TYME BANK</option>
                                <option> AFRICAN BANK </option>
                            </AvField>
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="mb-3">
                            <AvField name="accountType" label="Account Type" type="select" required>
                                <option> Select...</option>
                                <option> Saving </option>
                                <option> Current </option>
                            </AvField>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default InputForm