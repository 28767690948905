import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    users: "",
    servicePro: "",
    isAuthenticated: false,
    error: null,
};

const userSlice = createSlice({
    name: 'users',
    initialState, // state
    reducers: {
        Login: (state, action) => {
            state.users = action.payload
        },
        checkTokenSuccess: (state) => {
            state.isAuthenticated = true;
            state.error = null;
        },
        checkTokenFailure: (state) => {
            state.isAuthenticated = false;
            state.error = "Token invalid or expired";
        },
        setError: (state, action) => {
            state.error = action.payload;
        }
    },
});

export const loginUser = (state) => state.users.users
export const { Login } = userSlice.actions;
export default userSlice.reducer;
