import React from 'react'
import { useStore1Selector } from 'index';
import { loginUser } from '../../../Redux/Slices/userSlice';
import usePost from '../../../hooks/usePost';
import { VerificationLoanMsg } from '../../../constant/NotifyMessage';
import { Spinner } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import CustomBtn from 'components/ui/CustomBtn';


function ApproveForm({ reFetch, onClose, status, loanId, btnName }) {
    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;
    const { execute, data, pending } = usePost()

    const changeStatusFunc = (e, values) => {
        e.preventDefault();

        const Method = 'POST', endPoint = `loans/loanStatus/${loanId}/status`;
        const raw = JSON.stringify({
            "status": status,
            "note": values.note
        });
        execute(endPoint, raw, Method, VerificationLoanMsg, token)
    }


    if (data?.status === 'success') {
        onClose();
        setTimeout(() => {
            reFetch()
        }, 2000)
    }

    return (
        // <button className='btn text-white w-100' onClick={changeStatusFunc}>
        //     {pending ? <span>  <Spinner as="span" animation="border" size="sm" /> Loading...</span> : btnName} 
        // </button>
        <AvForm className="form-horizontal mt-4" onValidSubmit={(e, v) => changeStatusFunc(e, v)}>
            <div className="mb-3">
                <AvField
                    name="note"
                    label="Add a note"
                    className="form-control"
                    placeholder="Enter note"
                    type="textarea"
                    required
                    rows="10"
                />
            </div>

            <CustomBtn Pending={pending} btnName={btnName} />
        </AvForm>
    )
}

export default ApproveForm