import { useStore1Selector } from "index";
import React from "react";
import { BsEyeFill } from "react-icons/bs";
import { loginUser } from "../../../Redux/Slices/userSlice";
import ModalComp from "../../../components/common/Modal";
import SmallModal from "../../../components/common/SmallModal";
import Table from "../../../components/common/Table";
import ErrorPage from "../../../components/helper/ErrorPage";
import Loading from "../../../components/helper/Loading";
import LoadingAnimation from "../../../components/helper/Loading-animation";
import ViewLoan from "../../../components/views/ViewLoan";
import { loanRequestColumns } from "../../../constant/Columns";
import { SendFormMsg, ServerError } from "../../../constant/NotifyMessage";
import useFetch from "../../../hooks/useFetch";
import usePost from "../../../hooks/usePost";
import { formatDate } from "../../../utils/dateFormat";
import Modal from "./Actions";
import ApproveModal from "./ApproveForm";
import DeclinedLoan from "./DeclinedAction";

const NewRequest = () => {
    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;
    const res_data = [];
    const { data, loading, error, reFetch } = useFetch(
        `${process.env.REACT_APP_BACKEND_URL}/loans?status=pending&status=verification&sort=-createdAt`,
        token
    );
    const [openModal, setOpenModal] = React.useState(false);
    const [openApproveModal, setOpenApproveModal] = React.useState(false);
    const [declineModal, setDeclineModal] = React.useState(false);
    const [status, setStatus] = React.useState();
    const [loanId, setLoanId] = React.useState();
    const [btnName, setBtnName] = React.useState();
    const [modalTitle, setModalTitle] = React.useState();
    const [viewUserDet, setViewUserDet] = React.useState(false);
    const { execute, pending } = usePost();

    if (loading) return <LoadingAnimation />;
    if (error) return <ErrorPage message={ServerError} />;

    const verifyFunc = (id) => {
        setOpenModal(true);
        setStatus("verification");
        setLoanId(id);
        setBtnName("Submit");
        setModalTitle("Verified");
    };

    const approveFunc = (id) => {
        setOpenApproveModal(true);
        setStatus("approve");
        setLoanId(id);
        setBtnName("Submit");
        setModalTitle("Approved");
    };

    const declineFunc = (id) => {
        setDeclineModal(true);
        setLoanId(id);
        setBtnName("Declined");
        setModalTitle("Declined Loan");
    };

    const viewDetails = (id) => {
        setViewUserDet(true);
        setLoanId(id);
    };

    const sendFormFunc = (reqUserId, loanId) => {
        const Method = "POST",
            endPoint = "mandates";
        const raw = JSON.stringify({
            debitedAgree: false,
            debitedAgree2: false,
            trackingOfDate: false,
            authorized: false,
            agreement: false,
            user: reqUserId,
            loan: loanId,
        });

        execute(endPoint, raw, Method, SendFormMsg, token);
    };

    const filterArr = () => {
        data?.forEach((res) => {
            res_data.push({
                ...res,
                firstName: res?.user?.firstName,
                lastName: res?.user?.lastName,
                payment_Date: formatDate(res?.paymentDate),
                createdAt: formatDate(res?.createdAt),
                updatedAt: formatDate(res?.updatedAt),
                image: (
                    <img
                        src={`${process.env.REACT_APP_IMG_API}${res?.user?.photoProfile}`}
                        alt=""
                        width={50}
                        height={40}
                    />
                ),
                viewBtn: (
                    <button
                        className="btn btn__table  color__blue"
                        onClick={() => viewDetails(res?._id)}
                    >
                        <BsEyeFill size={14} /> View
                    </button>
                ),
                verifyBtn: (
                    <button
                        className="btn btn__table color__verify"
                        onClick={() => verifyFunc(res?._id)}
                    >
                        Verified
                    </button>
                ),
                downloadBtn: (
                    <button
                        className="btn btn__table color__download"
                        onClick={() => sendFormFunc(res?.user?._id, res?._id)}
                    >
                        Send A form
                    </button>
                ),
                approveBtn: (
                    <button
                        className="btn btn__table color__green"
                        onClick={() => approveFunc(res?._id)}
                    >
                        Approved
                    </button>
                ),
                declineBtn: (
                    <button
                        className="btn btn__table color__red"
                        onClick={() => declineFunc(res?._id)}
                    >
                        Declined
                    </button>
                ),
            });
        });
    };
    filterArr();

    return (
        <React.Fragment>
            {loading ? <Loading /> : <Table columns={loanRequestColumns} data={res_data} />}
            <ModalComp
                ModalTitle=""
                open={viewUserDet}
                onClose={() => setViewUserDet(false)}
                cancel="close"
                Component={
                    <ViewLoan onClose={() => setViewUserDet(false)} loan_Id={loanId} />
                }
            />

            {/* Verify modal */}
            <SmallModal
                size="sm"
                open={openModal}
                onClose={() => setOpenModal(false)}
                ModalTitle={modalTitle}
                cancel="close"
                Components={
                    <Modal
                        reFetch={reFetch}
                        onClose={() => setOpenModal(false)}
                        status={status}
                        loanId={loanId}
                        btnName={btnName}
                    />
                }
            />

            {/* Approve modal */}
            <SmallModal
                size="md"
                open={openApproveModal}
                onClose={() => setOpenApproveModal(false)}
                ModalTitle={modalTitle}
                cancel="close"
                Components={
                    <ApproveModal
                        reFetch={reFetch}
                        onClose={() => setOpenApproveModal(false)}
                        status={status}
                        loanId={loanId}
                        btnName={btnName}
                    />
                }
            />

            {/* Declined loan modal */}
            <SmallModal
                size="md"
                open={declineModal}
                onClose={() => setDeclineModal(false)}
                ModalTitle={modalTitle}
                cancel="close"
                Components={
                    <DeclinedLoan
                        reFetch={reFetch}
                        onClose={() => setDeclineModal(false)}
                        loanId={loanId}
                        btnName={btnName}
                    />
                }
            />
        </React.Fragment>
    );
};
export default NewRequest;
