import { MDBDataTable } from "mdbreact";
import { Card, CardBody } from 'reactstrap';

const Table = ({ columns, data, objData }) => {
    const dataDb = { columns, rows: data };


    const handleStatusChange = (event) => {
        const status = event.target.value;
        objData?.setCustomerStatus(status);
    };

    return (
        <Card className='card-border-radius'>
            <CardBody>
                {
                    !objData ? null : (<div className="form-group w-25 mb-4">
                        <label htmlFor="status">Select Customer Account Status</label>
                        <select
                            id="status"
                            className="form-control"
                            value={objData.customerStatus}
                            onChange={handleStatusChange}
                        >
                            <option value={true}> {objData?.option1} </option>
                            <option value={false}>{objData?.option2}</option>
                        </select>
                    </div>)
                }

                <MDBDataTable entries={5} entriesOptions={[5, 10, 50]} responsive bordered striped hover data={dataDb} fullPagination />
            </CardBody>
        </Card>
    );
};

export default Table;
