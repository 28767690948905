import React, { useEffect, useState } from 'react';
import { Col, Row } from "reactstrap";
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { formatCurrency } from '../../../utils/formatCurrency';

const labelMappings = {
    grossProfit: 'Net Profit',
    totalCost: 'Total Cost',
    totalInterest: 'Total Interest',
    totalLoanAmount: 'Total Loan Amount',
    totalPlusInterest: 'Total Plus Interest',
    totalDebtCollection: 'Total Debt Collection',
    totalDebtCollectionPlusInterest: 'Total Debt Collection Plus Interest',
    totalInterestDebtCollection: 'Total Interest Debt Collection',
};

function PieChart({ title, labels, data }) {

    const [formattedLabels, setFormattedLabels] = useState([]);

    useEffect(() => {
        const formatted = labels.map(label => labelMappings[label]);
        setFormattedLabels(formatted);
    }, [labels]);

    const options = {
        labels: formattedLabels,
        colors: ['#626ed4', '#02a499', '#f8b425', '#ff5b5b', '#7aafbf', '#f38630', '#e0e4cc'],
        dataLabels: {
            enabled: false
        },
        legend: {
            show: true,
            position: 'bottom'
        },
        tooltip: {
            custom: function ({ series, seriesIndex }) {
                if (formattedLabels.length > 0) {
                    return `<div class="arrow_box" >
                              <span>${formattedLabels[seriesIndex]}: ${formatCurrency(series[seriesIndex])}</span>
                            </div>`;
                }
                return '';
            }
        }
    };

    return (
        <Card className="card-border-radius" style={{ height: '500px' }}>
            <CardBody>
                <CardTitle>{title}</CardTitle>
                <div className="ct-chart wid pt-4">
                    {formattedLabels.length > 0 && (
                        <ReactApexChart options={options} series={data} type="pie" height="300" />
                    )}
                </div>
            </CardBody>
        </Card>
    );
}

function Report90days({ loanedOutReport, repaidReport }) {
    const loanedOutLabels = loanedOutReport ? Object.keys(loanedOutReport) : [];
    // const repaidLabels = repaidReport ? Object.keys(repaidReport) : [];

    const formattedLoanedOutLabels = loanedOutLabels.map(label => labelMappings[label] || label);
    // const formattedRepaidLabels = repaidLabels.map(label => labelMappings[label] || label);

    return (
        <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
                {loanedOutReport && loanedOutLabels.length > 0 && (
                    <PieChart
                        title="Loaned Out Report for 90 days"
                        labels={loanedOutLabels}
                        data={Object.values(loanedOutReport)}
                        formattedLabels={formattedLoanedOutLabels}
                    />
                )}
            </Col>
            {/* <Col xs={12} sm={12} md={6} lg={6}>
                {repaidReport && repaidLabels.length > 0 && (
                    <PieChart
                        title="Repaid Report For 90 days"
                        labels={repaidLabels}
                        data={Object.values(repaidReport)}
                        formattedLabels={formattedRepaidLabels}
                    />
                )}
            </Col> */}
        </Row>
    );
}

export default Report90days;
