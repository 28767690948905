import React from "react"
import { Link } from "react-router-dom"
import { Container, Row } from "reactstrap"
import { FaHeart } from "react-icons/fa";

const Footer = () => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <div className="col-12">
              Copy Right © {new Date().getFullYear()} <span className="d-none d-sm-inline-block">  <p className="my-3">  Develop with <FaHeart color="red" /> By <a href="https://www.websitebuilders.dev" target="_blank">Website Builders</a>
              </p></span>

            </div>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
