import React from "react";
import { Container } from "reactstrap";
import Breadcrumb from "../../components/common/Breadcrumb";
import MetaTag from "../../components/common/MetaTagSeo";
import { UsersPage } from "../../constant/SCO_Name";
import { UserTitle } from "../../constant/BreadCrum";
import { UsersRoute } from "../../constant/RouteName";
import useFetch from '../../hooks/useFetch';
import { ServerError } from "../../constant/NotifyMessage";
import Loading from "../../components/helper/Loading";
import { useStore1Selector } from "index";
import { loginUser } from "../../Redux/Slices/userSlice";
import { RiCheckDoubleLine } from "react-icons/ri";
import { ImCross } from "react-icons/im";
import { MdDeleteForever } from "react-icons/md";
import ErrorPage from "../../components/helper/ErrorPage";
import SmallModal from '../../components/common/SmallModal';
import ModalComp from '../../components/common/Modal';
import LoadingAnimation from "../../components/helper/Loading-animation";
import Table from "../../components/common/Table";
import { customerColumns } from "../../constant/Columns";
import ViewUser from "../../components/views/ViewUser";
import SuspendCustomer from "../../sections/Admin/customers/SuspendCustomer";


const Customers = () => {

    const [openModal_2, setOpenModal_2] = React.useState(false);
    const [deleteUser, setDeleteUser] = React.useState(false);
    const [viewUserDet, setViewUserDet] = React.useState(false);
    const [btnName, setBtnName] = React.useState();
    const [userId, setUserId] = React.useState();
    const [apiQuery, setApiQuery] = React.useState();
    const [activeCustomer, setActiveCustomer] = React.useState(true);

    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;
    const res_data = [];
    const { data, loading, error, reFetch } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/users?role=user&limit=500&sort=-createdAt&activeCustomer=${activeCustomer}`, token);

    if (loading) return <LoadingAnimation />
    if (error) return <ErrorPage message={ServerError} />

    const filterArr = () => {
        data?.forEach(res => {
            res_data.push({
                ...res,
                verify: <button className="btn-status text-white color__blue"> {res?.verified ? <RiCheckDoubleLine size={18} /> : <ImCross />} </button>,
                active: <button className={`btn-status text-white ${res?.activeCustomer ? 'color__green' : 'color__red'}`} > {res?.activeCustomer ? 'Active' : 'Inactive'} </button>,
                viewBtn: <button className="btn btn-danger color__blue" onClick={() => viewDetails(res._id)}> View </button>,
                actionBtn: res.status ? <button onClick={() => suspendUser(res._id)} className={`btn color__red`}> Suspend </button> : <button onClick={() => unSuspend(res._id)} className={`btn color__black`}> UnSuspend </button>,
                generateReport: <button className="btn btn-dark" onClick={() => generateReportFunc(res._id)}> Generate R </button>,
                deleteBtn: <button className="btn btn-danger" onClick={() => deleteUserFunc(res._id)}> <MdDeleteForever size={22} /> </button>,
            })
        });
    }
    filterArr();

    const suspendUser = (id) => {
        setOpenModal_2(true)
        setUserId(id)
        setBtnName("Suspend")
        setApiQuery("suspended")
    }
    const unSuspend = (id) => {
        setOpenModal_2(true)
        setUserId(id)
        setBtnName("Unsuspend")
        setApiQuery("un-suspended")
    }
    const generateReportFunc = (id) => {
        setOpenModal_2(true)
        setUserId(id)
        setBtnName("Generate Graph Report")
        setApiQuery("Generated")
    }
    const deleteUserFunc = (id) => {
        setDeleteUser(true)
        setUserId(id)
        setBtnName("Delete")
        setApiQuery("Deleted")
    }
    const viewDetails = (id) => {
        setViewUserDet(true)
        setUserId(id)
    }

    const objData = {
        "customerStatus": activeCustomer,
        "setCustomerStatus": setActiveCustomer,
        "option1": "Active",
        "option2": "In active",
    }
    return (
        <React.Fragment>
            <div className="page-content px-5">
                <Breadcrumb default={UsersRoute} defaultName="Users" title={UserTitle} />
                <MetaTag title_sco={UsersPage} />

                <Container fluid>
                    <div className="page-title-box">
                        {
                            loading ? <Loading /> : (
                                <Table
                                    columns={customerColumns}
                                    data={res_data}
                                    objData={objData}
                                />)
                        }
                    </div>
                </Container>
            </div>

            <ModalComp
                ModalTitle=""
                open={viewUserDet}
                onClose={() => setViewUserDet(false)}
                cancel="close"
                Component={<ViewUser onClose={() => setViewUserDet(false)} user_Id={userId} />}
            />

            <SmallModal
                open={openModal_2}
                onClose={() => setOpenModal_2(false)}
                ModalTitle="Are you sure you want to performed this action ?"
                cancel="close"
                Components={<SuspendCustomer reFetch={reFetch} onClose={() => setOpenModal_2(false)} user_Id={userId} btnName={btnName} apiQuery={apiQuery} />}
            />

            <SmallModal
                open={deleteUser}
                onClose={() => setDeleteUser(false)}
                ModalTitle="Are you sure you want to delete this user ?"
                cancel="close"
                Components={<SuspendCustomer reFetch={reFetch} onClose={() => setDeleteUser(false)} user_Id={userId} btnName={btnName} apiQuery={apiQuery} />}
            />

        </React.Fragment>
    )
}
export default Customers