import React from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";
import { Container } from "reactstrap";


function LoadingAnimation() {
    return (
        <React.Fragment>
            <div className="page-content mt-5">
                <Container fluid>
                    <div className='d-flex justify-content-center align-items-center'>
                        <span> <ScaleLoader color="#238ad1" /> </span>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default LoadingAnimation