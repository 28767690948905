import React, { useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody } from 'reactstrap';
import { formatCurrency } from "../../../utils/formatCurrency"

function Budgets({ totalLoanedOut, totalCredit }) {

    // console.log(" res : ", totalLoanedOut)

    const balance = totalCredit - totalLoanedOut?.totalLoanAmount
    const [options, setOptions] = useState({
        dataLabels: {
            enabled: false,
        },
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                donut: {
                    size: '80%'
                }
            }
        },
        colors: ['#626ed4', '#02a499', '#f8b425'],
        tooltip: {
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const labels = ['Total Loaned Out', 'Total Credits', 'Total Balance Remain'];
                return `<div class="arrow_box">
                          <span>${labels[seriesIndex]}: ${series[seriesIndex]}</span>
                        </div>`;
            }
        },
    });


    return (
        <Card className="card-border-radius">
            <CardBody>
                <h4 className="card-title mb-4">Budgets </h4>

                <div id="ct-donut" className="ct-chart wid pt-4">
                    <ReactApexChart options={options} series={[totalLoanedOut?.totalLoanAmount, totalCredit, balance]} type="donut" height="220" className="apex-charts" />
                </div>
                <div className="mt-4">
                    <table className="table mb-0">
                        <tbody>
                            <tr>
                                <td>
                                    <span className="badge bg-primary">Total loaned</span>
                                </td>
                                <td>Total Loaned Out</td>
                                <td className="text-end"> {formatCurrency(totalLoanedOut?.totalLoanAmount)} </td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="badge bg-success">Total Credits</span>
                                </td>
                                <td>Total Credits</td>
                                <td className="text-end"> {formatCurrency(totalCredit)}</td>
                            </tr>
                            <tr>
                                <td>
                                    <span className="badge bg-warning">Total Balance Remain </span>
                                </td>
                                <td>Total Balance Remain </td>
                                <td className="text-end"> {formatCurrency(balance)} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </CardBody>
        </Card>
    )
}

export default Budgets