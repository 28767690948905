import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody } from 'reactstrap';

const MonthlyRepaid = ({ data }) => {
    const [options, setOptions] = useState({});
    const [series, setSeries] = useState([]);

    useEffect(() => {
        const loanData = transformData(data);

        setOptions({
            title: {
                text: 'Repaid',
                align: 'center',
                margin: 20,
                offsetY: 0,
                style: {
                    fontSize: '16px',
                    color: '#666'
                }
            },
            chart: {
                toolbar: {
                    show: false,
                },
            },
            colors: ['#3c4ccf', '#02a499', '#ffbb44', '#dd5566', '#0cfa31', '#3c9f3f'],
            plotOptions: {
                bar: {
                    columnWidth: '50%',
                    dataLabels: {
                        show: false
                    },
                },
            },
            legend: {
                show: true
            },
            dataLabels: {
                enabled: false,
            },
            grid: {
                borderColor: '#f8f8fa',
                row: {
                    colors: ['transparent', 'transparent'],
                    opacity: 0.5
                },
            },
            stroke: {
                show: true,
                width: 1.5,
                colors: ['#fff']
            },
            xaxis: {
                categories: loanData.months,
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                }
            },
            yaxis: {
                tickAmount: 3,
                labels: {
                    formatter: function (val) {
                        return val.toFixed(0)
                    }
                },
            }
        });

        setSeries([
            {
                name: 'Total Amount',
                data: loanData.totalLoanAmount
            },
            {
                name: 'Interest',
                data: loanData.totalInterests
            },
            {
                name: 'Total  +  Interest',
                data: loanData.totalPlusInterests
            },
            {
                name: 'Total  Debt Collection',
                data: loanData.totalDebtCollection
            },
            {
                name: 'Total Interest Debt Collection',
                data: loanData.totalInterestDebtCollection
            },
            {
                name: 'Total Debt Collection + Interest Debt Collection',
                data: loanData.totalDebtCollectionPlusInterest
            },
        ]);

    }, [data]);
    const monthNames = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];

    const transformData = (data) => {
        let months = [...new Set(data.map(entry => entry.month.toLowerCase()))];

        // Sort the months
        months = months.sort((a, b) => monthNames.indexOf(a) - monthNames.indexOf(b));

        const totalLoanAmount = months.map(month =>
            data.find(entry => entry.month.toLowerCase() === month).totalLoanAmount
        );
        const totalInterests = months.map(month =>
            data.find(entry => entry.month.toLowerCase() === month).totalInterest
        );
        const totalPlusInterests = months.map(month =>
            data.find(entry => entry.month.toLowerCase() === month).totalPlusInterest
        );
        const totalDebtCollection = months.map(month =>
            data.find(entry => entry.month.toLowerCase() === month).totalDebtCollection
        );
        const totalInterestDebtCollection = months.map(month =>
            data.find(entry => entry.month.toLowerCase() === month).totalInterestDebtCollection
        );
        const totalDebtCollectionPlusInterest = months.map(month =>
            data.find(entry => entry.month.toLowerCase() === month).totalDebtCollectionPlusInterest
        );

        return {
            months,
            totalLoanAmount,
            totalInterests,
            totalPlusInterests,
            totalDebtCollection,
            totalInterestDebtCollection,
            totalDebtCollectionPlusInterest
        };
    }

    return (
        <React.Fragment>
            <Card className="card-border-radius">
                <CardBody>
                    <ReactApexChart options={options} series={series} type="bar" height="290" />
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default MonthlyRepaid;
