const LoginMsg = "Successfully logged In";
const ForgotPasswordMsg = "Successfully send the link to your email";
const ResetPasswordMsg = "Successfully update your password";
const LogoutMsg = "Successfully logout";
const RegisterMsg = "Successfully registered";
const verifyMsg = "Successfully verified";
const UpdatePasswordMsg = "Password successfully updated";
const updateProfileMsg = "Successfully updated the profile";
const AddBookMarkMsg = "Successfully add to book mark";
const RemoveBookMarkMsg = "Successfully removed ";
const CreateCourseMsg = "Successfully create a course ";
const DeleteCourseMsg = "Successfully deleted ";
const CreateSessionMsg = "Successfully create a session  ";
const SuspendedMsg = "Successfully suspended  ";
const UnSuspendedMsg = "Successfully unsuspended  ";
const ApprovedMsg = "Successfully Approved  ";
const DeclineMsg = "Successfully Declined  ";
const ApplyLongMsg = "Successfully applied  ";
const ServerError = "Something went wrong  ";
const ApprovedLoanMsg = "Successfully approved  ";
const DeclinedLoanMsg = "Successfully declined  ";
const VerificationLoanMsg = "Successfully send";
const SuspendUserMsg = "Successfully suspend the user  ";
const PaidPercentageMsg = "Successfully paid  ";
const GracePeriodMsg = "Successfully Move to grace period  ";
const SendFormMsg = "Successfully sent the form application ";
const FormCompletedMsg = "Successfully completed the form ";
const AssignCreditMsg = "Successfully assigned credit ";
const UpdateCreditMsg = "Successfully updated ";
const AddCostMsg = "Successfully Add cost ";
const DebtCollectionMsg = "Successfully";

export {
  LoginMsg,
  ForgotPasswordMsg,
  ResetPasswordMsg,
  LogoutMsg,
  RegisterMsg,
  verifyMsg,
  UpdatePasswordMsg,
  updateProfileMsg,
  AddBookMarkMsg,
  RemoveBookMarkMsg,
  CreateCourseMsg,
  DeleteCourseMsg,
  CreateSessionMsg,
  SuspendedMsg,
  UnSuspendedMsg,
  ApprovedMsg,
  DeclineMsg,
  ApplyLongMsg,
  ServerError,
  ApprovedLoanMsg,
  DeclinedLoanMsg,
  VerificationLoanMsg,
  SuspendUserMsg,
  PaidPercentageMsg,
  SendFormMsg,
  FormCompletedMsg,
  AssignCreditMsg,
  UpdateCreditMsg,
  AddCostMsg,
  DebtCollectionMsg,
  GracePeriodMsg
};
