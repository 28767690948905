const DashboardRoute = "/dashboard";
const AdminDashboardRoute = "/admin-dashboard";
const LoginRoute = "/login";
const ForgotPasswordRoute = "/forgot-password";
const ResetPasswordRoute = "/resetPassword/:token";
const RegisterRoute = "/register";
const StudentRegRoute = "/student";
const VerifyEmailRoute = "/verify/:userId/:verifyToken";
const LogoutRoute = "/logout";
const RegisterAsRoute = "/register-as";
const ProfileRoute = "/my-profile";
const CustomerDashboardRoute = "/customer-dashboard";
const ApproveLoanRoute = "/approve-loans";
const DeclineLoanRoute = "/decline-loans";
const UsersRoute = "/users";
const LoanRequestRoute = "/loan-request";
const ApprovedLoanRoute = "/approved-loans";
const TeamRoute = "/my-team";
const MondateFormroute = "/mondate-form";
const MondateFormrouteID = "/mondate-form/:id";
const MondateApplicationRoute = "/application/:id";
const DeclinedLoanRoute = "/declined-loans";
const PaidLoanRoute = "/paid-loans";
const DebtCollectionRoute = "/debt-collection";
const MandateUserRoute = "/mandate-forms";
const ActionRequiredRoute = "/action-required";
const LoansTabsRoute = "/loans-Tabs";
const GracePeriodRoute = "/grace-period";
const PreAgreementRoute = "/pre-agreement";

//* Managment links
const DebtCollectorsMngt = "/debt-collectors";
const PaidLoansMngt = "/management-paid-loans";
const BlackListMng = "/blacklisted";
const FinancialStatmentMngt = "/financial-statment";
const MandateFormLinks = "/mandate-form-links";
const AdminRegisterUserRoute = "/admin-register-user";

//* costs
const CostRoute = "/costs";


export {
  ActionRequiredRoute, AdminDashboardRoute, AdminRegisterUserRoute, ApproveLoanRoute, ApprovedLoanRoute, BlackListMng, CostRoute, CustomerDashboardRoute, DashboardRoute, DebtCollectionRoute, DebtCollectorsMngt, DeclineLoanRoute, DeclinedLoanRoute, FinancialStatmentMngt, ForgotPasswordRoute, GracePeriodRoute, LoanRequestRoute, LoansTabsRoute, LoginRoute, LogoutRoute, MandateFormLinks, MandateUserRoute, MondateApplicationRoute, MondateFormroute, MondateFormrouteID, PaidLoanRoute, PaidLoansMngt, PreAgreementRoute, ProfileRoute, RegisterAsRoute, RegisterRoute, ResetPasswordRoute,
  StudentRegRoute, TeamRoute, UsersRoute, VerifyEmailRoute
};

