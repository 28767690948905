// utils/verifyToken.js
import axios from 'axios';

export const verifyToken = async (history) => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get('http://localhost:4000/api/v1/users/check-token-valid', {
            headers: { Authorization: token }
        });

        console.log("Verified response:", response);

        if (response.data.status === 'fail') {
            throw new Error(response.data.message);
        }
    } catch (error) {
        console.error('Token verification failed:', error.message);
        localStorage.removeItem('token');
        history.push('/login');
    }
};

