import {
  ActionRequiredRoute,
  AdminDashboardRoute,
  CostRoute,
  DebtCollectionRoute,
  GracePeriodRoute,
  LoansTabsRoute,
  MondateFormroute,
  PreAgreementRoute,
  TeamRoute,
  UsersRoute
} from "constant/RouteName";
import useFetch from "hooks/useFetch";
import { useStore1Selector } from "index";
import { FaUsers, FaWpforms } from "react-icons/fa";
import { GiMoneyStack, GiReceiveMoney, GiTeamDowngrade, } from "react-icons/gi";
import { LiaBusinessTimeSolid } from "react-icons/lia";
import { MdDashboard, MdOutlinePendingActions } from "react-icons/md";
import { RiMoneyDollarCircleFill } from "react-icons/ri";
import { Link } from "react-router-dom";
import { loginUser } from "Redux/Slices/userSlice";

function Admin() {

  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;

  const { data: loans } = useFetch(
    `${process.env.REACT_APP_BACKEND_URL}/loans?status=pending&status=verification&sort=-createdAt`,
    token
  );
  const { data: actionRequired } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/loans?status=action_required&sort=-createdAt`, token);

  const { data: gracePeriod } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/loans?status=grace-period&sort=-createdAt`, token);

  const { data: debt } = useFetch(
    `${process.env.REACT_APP_BACKEND_URL}/loans/debt?status=debtCollection&sort=-createdAt`,
    token
  );

  return (
    <>
      <li className="menu-title">Admin</li>
      <li>
        <Link to={AdminDashboardRoute} className="waves-effect">
          <span>
            <MdDashboard /> Dashboard
          </span>
        </Link>
      </li>

      <li>
        <Link to={LoansTabsRoute} className="waves-effect">
          <span className="badge rounded-pill bg-success float-end">{loans?.length}</span>
          <span><GiReceiveMoney /> New Requests </span>
        </Link>
      </li>

      <li>
        <Link to={PreAgreementRoute} className="waves-effect">
          <span> <FaWpforms /> Pre Agreement </span>
        </Link>
      </li>

      <li>
        <Link to={MondateFormroute} className="waves-effect">
          <span> <FaWpforms /> Mandate form</span>
        </Link>
      </li>

      <li>
        <Link to={ActionRequiredRoute} className="waves-effect">
          <span className="badge rounded-pill bg-warning float-end">{actionRequired?.length}</span>
          <span> <MdOutlinePendingActions /> Action Required</span>
        </Link>
      </li>
      <li>
        <Link to={GracePeriodRoute} className="waves-effect">
          <span className="badge rounded-pill bg-primary float-end">{gracePeriod?.length}</span>
          <span> <LiaBusinessTimeSolid /> Grace Period</span>
        </Link>
      </li>
      <li>
        <Link to={DebtCollectionRoute} className="waves-effect">
          <span className="badge rounded-pill bg-danger float-end">{debt?.length} </span>
          <span><GiMoneyStack /> Debt Collection</span>
        </Link>
      </li>

      <li>
        <Link to={CostRoute} className="waves-effect">
          <span><RiMoneyDollarCircleFill /> Costs </span>
        </Link>
      </li>

      <li>
        <Link to={UsersRoute} className="waves-effect">
          <span><FaUsers /> Customers </span>
        </Link>
      </li>

      <li>
        <Link to={TeamRoute} className="waves-effect">
          <span> <GiTeamDowngrade /> Team Members </span>
        </Link>
      </li>
    </>
  );
}

export default Admin;
