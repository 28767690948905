import React, { useEffect } from "react"
import { Container } from "reactstrap";
import Breadcrumb from "../../components/common/Breadcrumb";
import MetaTag from "../../components/common/MetaTagSeo";
import { DeclineLoanPage } from "../../constant/SCO_Name";
import { DashboardPageDefault, DeclineLoanPageTitle } from "../../constant/BreadCrum";
import { loginUser } from "../../Redux/Slices/userSlice";
import { useStore1Selector } from 'index';
import usePost from '../../hooks/usePost';
import { Link } from 'react-router-dom';
import { CustomerDashboardRoute } from "../../constant/RouteName";
import { BsArrowLeft } from 'react-icons/bs';
import EmptyMessage from "../../components/helper/EmptyMessage";
import LoadingAnimation from "../../components/helper/Loading-animation";
import Lists from "../../components/common/Lists";

const DeclineLoans = () => {

    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;
    const userId = userDet?.data?.data?._id;

    const noToast = true
    const { execute, data, pending } = usePost()

    useEffect(() => {
        const Method = 'POST', endPoint = `loans/loanStatus/${userId}`;
        const raw = JSON.stringify({ "status": "decline" });
        execute(endPoint, raw, Method, noToast, token)
    }, [userId])

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb default={DashboardPageDefault} title={DeclineLoanPageTitle} />
                <MetaTag title_sco={DeclineLoanPage} />

                <Container fluid>
                    <div className="page-title-box mx-4">
                        <Link to={CustomerDashboardRoute}> <BsArrowLeft /> Back</Link>
                        <div className="mt-3">
                            {pending ? (
                                <LoadingAnimation />
                            ) : (
                                <>
                                    {data?.data?.data?.length > 0 ? (
                                        <Lists data={data.data.data} hideStatus={false} />
                                    ) : (
                                        <EmptyMessage />
                                    )}
                                </>
                            )}

                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default DeclineLoans