import { useStore1Selector } from 'index';
import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";
import Breadcrumb from "../../components/common/Breadcrumb";
import Lists from "../../components/common/Lists";
import MetaTag from "../../components/common/MetaTagSeo";
import ModalComp from "../../components/common/Modal";
import Loading from "../../components/helper/Loading";
import { DashboardPageDefault, DashboardPageTitle } from "../../constant/BreadCrum";
import { LoangPage } from "../../constant/SCO_Name";
import useFetch from '../../hooks/useFetch';
import usePost from '../../hooks/usePost';
import { loginUser } from "../../Redux/Slices/userSlice";
import LoanCardProcess from "../../sections/Users/dashboard/LoanCardProcess";
import LoanForm from "../../sections/Users/dashboard/LoanForm";
import LoanProcess from "../../sections/Users/dashboard/LoanProcess";

const Dashboard = () => {

    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;
    const userId = userDet?.data?.data?._id;
    let approve, decline;
    const [openModal, setOpenModal] = useState(false);

    //* Fetch all my loans
    const { data, loading, length, error, reFetch } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/loans/loanStatus/${userId}`, token);
    const { data: creditData, } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/credits/user/${userId}`, token);

    //* Fetch approve loans
    {
        const noToast = true
        const { execute, lengths, data } = usePost()
        approve = lengths;
        useEffect(() => {
            const Method = 'POST', endPoint = `loans/loanStatus/${userId}`;
            const raw = JSON.stringify({ "status": "approve" });
            execute(endPoint, raw, Method, noToast, token)
        }, [userId])
    }

    //* Fetch Decline loans
    {
        const noToast = true
        const { execute, lengths, data } = usePost()
        decline = lengths;
        useEffect(() => {
            const noToast = true
            const Method = 'POST', endPoint = `loans/loanStatus/${userId}`;
            const raw = JSON.stringify({ "status": "decline" });
            execute(endPoint, raw, Method, noToast, token)
        }, [userId])
    }

    let lastElement = data[data.length - 1];

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb default={DashboardPageDefault} title={DashboardPageTitle} />
                <MetaTag title_sco={LoangPage} />

                <Container fluid>
                    <div className="page-title-box mx-4">
                        <button className="btn text-white apply__btn mb-4" onClick={() => setOpenModal(true)}> Apply here</button>
                        <p style={{ fontSize: '1.3em' }}> Maximum Amount : {creditData[creditData.length - 1]?.creditLimit} </p>
                        <p style={{ fontSize: '1.3em' }}> Current Borrowed : {creditData[creditData.length - 1]?.currentMonthBorrowed} </p>

                        {lastElement?.status === "paid" ? null : <LoanProcess lastElement={lastElement} />}
                        <LoanCardProcess length={length} approveLength={approve} declineLength={decline} />

                        {loading ? <Loading /> : <Lists data={data} hideStatus={true} reFetch={reFetch} />}
                    </div>
                </Container>
            </div>

            <ModalComp
                ModalTitle=""
                open={openModal}
                onClose={() => setOpenModal(false)}
                cancel="close"
                Component={<LoanForm onClose={() => setOpenModal(false)} reFetch={reFetch} />}
            />
        </React.Fragment>
    )
}

export default Dashboard