import React, { useEffect, useState } from 'react'
import { useStore1Selector } from "index";
import useFetch from 'hooks/useFetch';
import { loginUser } from "Redux/Slices/userSlice";
import { Col, Row, CardBody, Collapse, Button, Card } from 'reactstrap';
import LoadingAnimation from 'components/helper/Loading-animation';
import { UpdateCreditMsg } from 'constant/NotifyMessage';
import { AvField, AvForm } from 'availity-reactstrap-validation';
import usePost from "hooks/usePost";
import { Form } from 'react-bootstrap';

function ViewUser({ user_Id }) {

    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;

    const { data, loading } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/users/${user_Id}`, token);
    const { data: creditData } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/credits/user/${user_Id}`, token);

    const [isOpen, setIsOpen] = useState(false);
    const [activateCustomer, setActivateCustomer] = useState(null);

    useEffect(() => {
        setActivateCustomer(data.activeCustomer)
    }, [data])

    const toggle = () => setIsOpen(!isOpen);
    const { execute } = usePost()

    if (loading) return <LoadingAnimation />

    const handleValidEditSubmit = (e, values) => {
        e.preventDefault();
        const Method = 'PATCH', endPoint = `credits/${creditData[0].id}`, isJSON = false;
        const raw = JSON.stringify({
            "creditLimit": values.creditLimit,
            "currentMonthBorrowed": values.currentMonthBorrowed,
            "userId": user_Id
        })

        execute(endPoint, raw, Method, UpdateCreditMsg, token, isJSON)
    }

    const handleSwitchChange = () => {
        setActivateCustomer(currentState => {
            const updatedState = !currentState;

            const Method = 'PATCH', endPoint = `users/${user_Id}`, isJSON = false;
            const body = JSON.stringify({
                "activeCustomer": updatedState
            });

            execute(endPoint, body, Method, UpdateCreditMsg, token, isJSON);

            return updatedState; // Return the new state to be set.
        });
    };


    return (
        <Row>

            <div className="profile__img d-flex justify-content-center align-items-center mb-3">
                <img className="avatar-lg" src={data?.photoProfile} />
            </div>

            <Row className="d-flex flex-row desktop__only">

                {/* Personal Information Card */}
                <Col md={7} lg={7} xs={12} sm={12}>
                    <div className="h-100-mobile">
                        <CardBody>
                            <h5 className="card-title section__header mb__mobile">Personal details</h5>

                            <Row>
                                <Col md={6}>
                                    <label className="form-label">First Name *</label>
                                    <input type="text" className="form-control" aria-label="First name" value={data?.firstName || 'N/A'} readOnly />

                                </Col>
                                <Col md={6}>
                                    <label className="form-label">Last Name *</label>
                                    <input type="text" className="form-control" aria-label="Last name" value={data?.lastName || 'N/A'} readOnly />

                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <label className="form-label">Email Address *</label>
                                    <input type="email" className="form-control" aria-label="Email address" value={data?.email || 'N/A'} readOnly />

                                </Col>
                                <Col md={6}>
                                    <label className="form-label">Phone Number *</label>
                                    <input type="text" className="form-control" aria-label="Phone number" value={data?.phoneNumber || 'N/A'} readOnly />

                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <label className="form-label">Gender *</label>
                                    <input type="text" className="form-control" aria-label="Gender" value={data?.gender || 'N/A'} readOnly />

                                </Col>
                                <Col md={6}>
                                    <label className="form-label">Black Listed *</label>
                                    <input type="text" className="form-control" aria-label="Black listed" value={data?.blackListed ? "Yes" : "No"} readOnly />
                                </Col>
                            </Row>

                        </CardBody>
                    </div>
                </Col>

                <Col md={5} lg={5} xs={12} sm={12} >
                    <div className="h-100-mobile">
                        <CardBody>
                            <h5 className="card-title section__header mb__mobile">Employment details</h5>
                            <label className="form-label">Company Name *</label>
                            <input type="text" className="form-control" aria-label="Company name" value={data?.companyName || 'N/A'} readOnly />

                            <label className="form-label">Job Title *</label>
                            <input type="text" className="form-control" aria-label="Job title" value={data?.jobTitle || 'N/A'} readOnly />

                            <label className="form-label">ID Number *</label>
                            <input type="text" className="form-control" aria-label="ID number" value={data?.idNumber} readOnly />
                        </CardBody>
                    </div>
                </Col>
            </Row>

            <Row className="d-flex flex-row desktop__only">
                {/* Address Card */}
                <Col md={6} lg={6} xs={12} sm={12}>
                    <div className="h-100-mobile">
                        <CardBody>
                            <h5 className="card-title section__header mb__mobile">Address</h5>
                            <label className="form-label">Street Address *</label>
                            <input type="text" className="form-control" aria-label="Street address" value={data?.streetAddress || 'N/A'} readOnly />

                            <Row>
                                <Col md={6}>
                                    <label className="form-label">City *</label>
                                    <input type="text" className="form-control" aria-label="City" value={data?.city || 'N/A'} readOnly />
                                </Col>
                                <Col md={6}>
                                    <label className="form-label">Province *</label>
                                    <input type="text" className="form-control" aria-label="Province" value={data?.province || 'N/A'} readOnly />
                                </Col>
                            </Row>
                            <Row>
                                <Col md={4}>
                                    <label className="form-label">Postal Code *</label>
                                    <input type="text" className="form-control" aria-label="Postal code" value={data?.postalCode || 'N/A'} readOnly />
                                </Col>
                                <Col md={4}>
                                    <label className="form-label">Area Code *</label>
                                    <input type="text" className="form-control" aria-label="Area code" value={data?.areaCode || 'N/A'} readOnly />

                                </Col>
                                <Col md={4}>
                                    <label className="form-label">Referral Code *</label>
                                    <input type="text" className="form-control" aria-label="Referral code" value={data?.referralCode || 'N/A'} readOnly />
                                </Col>
                            </Row>

                        </CardBody>
                    </div>
                </Col>

                {/* Banking Information Card */}
                <Col md={6} lg={6} xs={12} sm={12}>
                    <div className="h-100-mobile">
                        <CardBody>
                            <h5 className="card-title section__header mb__mobile">Banking Information</h5>
                            <label className="form-label">Account Number *</label>
                            <input type="text" className="form-control" aria-label="Account number" value={data?.accountNumber} readOnly />

                            <label className="form-label">Account Type *</label>
                            <input type="text" className="form-control" aria-label="Account type" value={data?.accountType} readOnly />

                            <label className="form-label">Bank Name *</label>
                            <input type="text" className="form-control" aria-label="Account type" value={data?.bankName} readOnly />
                        </CardBody>
                    </div>

                </Col>

                <div className='d-flex justify-content-end'>
                    <Form>
                        <Form.Check
                            type="switch"
                            id="custom-switch"
                            label={activateCustomer ? "Active" : "In activate"}
                            checked={activateCustomer}
                            onChange={handleSwitchChange}
                        />
                    </Form>
                </div>

            </Row>

            <AvForm className='p-3' onValidSubmit={(e, v) => { handleValidEditSubmit(e, v) }}>
                <Row className='d-flex align-items-center'>
                    <h5 className="card-title section__header mb__mobile"> Credit balance </h5>
                    <Col md={3} lg={3} xs={12} sm={12}>
                        <label className="form-label">Maximum Amount</label>
                        <AvField type="text" className="form-control" aria-label="Account number" name="creditLimit" value={creditData[creditData.length - 1]?.creditLimit} />
                    </Col>

                    <Col md={3} lg={3} xs={12} sm={12}>
                        <label className="form-label"> Current borrowed</label>
                        <AvField type="text" className="form-control" aria-label="Account type" name="currentMonthBorrowed" value={creditData[creditData.length - 1]?.currentMonthBorrowed} />
                    </Col>
                    <Col md={3} lg={3} xs={12} sm={12}>
                        <button className="btn custom-btn  text-white mt-4" type="submit">
                            <span className="me-2"> Edit </span>
                        </button>
                    </Col>
                </Row>
            </AvForm>

            <div className='mt-4'>
                <Button color="primary" onClick={toggle} style={{ marginBottom: '1rem' }}>
                    Show Passport Photo
                </Button>
                <Collapse isOpen={isOpen}>
                    <Card>
                        <CardBody>
                            <div style={{ width: '100%', height: '50%' }}>
                                <img width="100%" src={data?.passportPhoto} alt="Passport Photo" />
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>
            </div>

        </Row>
    )
}

export default ViewUser