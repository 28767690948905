import React, { useState } from "react"
import { Container } from "reactstrap";
import Breadcrumb from "../../components/common/Breadcrumb";
import MetaTag from "../../components/common/MetaTagSeo";
import { UsersPage } from "../../constant/SCO_Name";
import { UserTitle } from "../../constant/BreadCrum";
import { UsersRoute } from "../../constant/RouteName";
import useFetch from '../../hooks/useFetch';
import { ServerError } from "../../constant/NotifyMessage";
import Loading from "../../components/helper/Loading";
import { useStore1Selector } from "index";
import { loginUser } from "../../Redux/Slices/userSlice";
import { MdDeleteForever } from "react-icons/md";
import ModalComp from '../../components/common/Modal';
import SmallModal from '../../components/common/SmallModal';
import ErrorPage from "../../components/helper/ErrorPage";
import LoadingAnimation from "../../components/helper/Loading-animation";
import { teamColumns } from "../../constant/Columns";
import Table from "../../components/common/Table";
import AddTeaMemberForm from "../../sections/Admin/teams/AddTeaMemberForm";
import SuspendTeaMember from "../../sections/Admin/teams/SuspendTeaMember";

const Team = () => {

    const [openModal, setOpenModal] = useState(false);
    const [openModal_2, setOpenModal_2] = useState(false);
    const [deleteUser, setDeleteUser] = useState(false);
    const [btnName, setBtnName] = React.useState();
    const [userId, setUserId] = React.useState();
    const [apiQuery, setApiQuery] = React.useState();

    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;
    const res_data = [];

    const { data, loading, error, reFetch } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/users?role=worker&sort=-createdAt`, token);

    if (loading) return <LoadingAnimation />
    if (error) return <ErrorPage message={ServerError} />

    const filterArr = () => {
        data?.forEach(res => {
            res_data.push({
                ...res,
                actionBtn: res.status ? <button onClick={() => suspendUser(res._id)} className={`btn color__red`}> Suspend </button> : <button onClick={() => unSuspend(res._id)} className={`btn color__black`}> UnSuspend </button>,
                deleteBtn: <button className="btn btn-danger" onClick={() => deleteUserFunc(res._id)}> <MdDeleteForever size={22} /> </button>,
            })
        });
    }
    filterArr();

    const suspendUser = (id) => {
        setOpenModal_2(true)
        setUserId(id)
        setBtnName("Suspend")
        setApiQuery("suspended")
    }
    const unSuspend = (id) => {
        setOpenModal_2(true)
        setUserId(id)
        setBtnName("Unsuspend")
        setApiQuery("un-suspended")
    }
    const deleteUserFunc = (id) => {
        setDeleteUser(true)
        setUserId(id)
        setBtnName("Delete")
        setApiQuery("Deleted")
    }

    return (
        <React.Fragment>

            <div className="page-content px-5">
                <Breadcrumb default={UsersRoute} defaultName="Users" title={UserTitle} />
                <MetaTag title_sco={UsersPage} />

                <Container fluid>
                    {userDet?.data?.data?.role === "admin" ? <button className="btn   color__blue" onClick={() => setOpenModal(true)}>+ add team member   </button> : null}
                    <div className="page-title-box">
                        {loading ? <Loading /> : (<Table columns={teamColumns} data={res_data} />)}
                    </div>
                </Container>
            </div>

            <ModalComp
                ModalTitle=""
                open={openModal}
                onClose={() => setOpenModal(false)}
                cancel="close"
                Component={<AddTeaMemberForm onClose={() => setOpenModal(false)} reFetch={reFetch} />}
            />

            <SmallModal
                open={openModal_2}
                onClose={() => setOpenModal_2(false)}
                ModalTitle="Are you sure you want to take this action ?"
                cancel="close"
                Components={<SuspendTeaMember reFetch={reFetch} onClose={() => setOpenModal_2(false)} user_Id={userId} btnName={btnName} apiQuery={apiQuery} />}
            />

            <SmallModal
                open={deleteUser}
                onClose={() => setDeleteUser(false)}
                ModalTitle="Are you sure you want to delete this user ?"
                cancel="close"
                Components={<SuspendTeaMember reFetch={reFetch} onClose={() => setDeleteUser(false)} user_Id={userId} btnName={btnName} apiQuery={apiQuery} />}
            />

        </React.Fragment>
    )
}
export default Team