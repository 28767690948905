import React from "react";
import { Container, Progress } from "reactstrap";
import Breadcrumb from "../../components/common/Breadcrumb";
import MetaTag from "../../components/common/MetaTagSeo";
import Cards from "../../sections/Admin/dashboard/Cards";
import { DashboardPage } from "../../constant/SCO_Name";
import { AdminDashboardRoute } from "../../constant/RouteName";
import { DashboardPageDefault, DashboardPageAdmin } from "../../constant/BreadCrum";
import useFetch from "../../hooks/useFetch";
import Loading from "../../components/helper/Loading";
import ErrorPage from "../../components/helper/ErrorPage";
import { ServerError } from "../../constant/NotifyMessage";
import { MdDeleteForever } from "react-icons/md";
import SmallModal from "../../components/common/SmallModal";
import { loginUser } from "../../Redux/Slices/userSlice";
import { useStore1Selector } from "index";
import LoadingAnimation from "../../components/helper/Loading-animation";
import { dashboardColumns } from "../../constant/Columns";
import Table from "../../components/common/Table";
import DeleteModal from "../../sections/Admin/dashboard/DeleteModal";

const Dashboard = () => {
  const userDet = useStore1Selector(loginUser);
  const [deleteRequest, setDeleteRequest] = React.useState(false);
  const [btnName, setBtnName] = React.useState();
  const [requestId, setRequestId] = React.useState();
  const [apiQuery, setApiQuery] = React.useState();

  const res_data = [];
  let pendingLenght, paidLenght;
  const { data, loading, error, reFetch } = useFetch(
    `${process.env.REACT_APP_BACKEND_URL}/loans?sort=-createdAt`,
    null
  );

  {
    const { data } = useFetch(
      `${process.env.REACT_APP_BACKEND_URL}/loans?status=pending&sort=-createdAt`,
      null
    );
    pendingLenght = data.length;
  }
  {
    const { data } = useFetch(
      `${process.env.REACT_APP_BACKEND_URL}/loans?status=paid&sort=-createdAt`,
      null
    );
    paidLenght = data.length;
  }

  // console.log(" res_data : ", res_data);

  if (loading) return <LoadingAnimation />;
  if (error) return <ErrorPage message={ServerError} />;

  const filterArr = () => {
    data.forEach((res) => {
      res_data.push({
        ...res,
        firstName: res?.user?.firstName,
        lastName: res?.user?.lastName,
        payment_Date: res?.paymentDate?.split("T")[0],
        btnStatus: (
          <button
            className={`btn-status ${res.status === "paid" ? "color__green" : "color__warning"
              }`}
          >
            {" "}
            {res.status === "paid" ? "Paid" : "Pending"}
          </button>
        ),
        progressBar: (
          <div className="text-center">
            % {res?.loanPercentage}
            <Progress
              color={
                res?.loanPercentage <= 50
                  ? "danger"
                  : res?.loanPercentage <= 99
                    ? "info"
                    : "success"
              }
              value={res?.loanPercentage}
            />
          </div>
        ),
        deleteBtn: (
          <button
            className="btn btn-danger"
            onClick={() => deleteRequestFunc(res._id)}
          >
            <MdDeleteForever size={22} />
          </button>
        ),
      });
    });
  };
  filterArr();

  const deleteRequestFunc = (id) => {
    setDeleteRequest(true);
    setRequestId(id);
    setBtnName("Delete");
    setApiQuery("Deleted");
  };

  return (
    <React.Fragment>
      <div className="page-content px-5">
        <Breadcrumb
          default={AdminDashboardRoute}
          defaultName={DashboardPageDefault}
          title={DashboardPageAdmin}
        />
        <MetaTag title_sco={DashboardPage} />

        <Container fluid>
          <div className="page-title-box">
            {loading ? (
              <Loading />
            ) : (
              <>
                <Cards
                  length={data.length}
                  paidLenght={paidLenght}
                  pendingLenght={pendingLenght}
                />
                <Table columns={dashboardColumns} data={res_data} />
              </>
            )}
          </div>
        </Container>
      </div>

      <SmallModal
        open={deleteRequest}
        onClose={() => setDeleteRequest(false)}
        ModalTitle="Are you sure you want to delete this  ?"
        cancel="close"
        Components={
          <DeleteModal
            reFetch={reFetch}
            onClose={() => setDeleteRequest(false)}
            request_Id={requestId}
            btnName={btnName}
            apiQuery={apiQuery}
          />
        }
      />
    </React.Fragment>
  );
};

export default Dashboard;
