import React from "react"
import { Container } from "reactstrap";
import Breadcrumb from "../../components/common/Breadcrumb";
import MetaTag from "../../components/common/MetaTagSeo";
import { LoanRequestPage } from "../../constant/SCO_Name";
import { LoanRequestRoute } from "../../constant/RouteName";
import useFetch from '../../hooks/useFetch';
import { ServerError } from "../../constant/NotifyMessage";
import Loading from "../../components/helper/Loading";
import { useStore1Selector } from "index";
import { loginUser } from "../../Redux/Slices/userSlice";
import SmallModal from '../../components/common/SmallModal';
import Modal from "../../sections/Admin/action-required/UpdatePaidAmount";
import DebtCollection from "../../sections/Admin/action-required/DebtCollection";
import ModalComp from '../../components/common/Modal';
import ErrorPage from "../../components/helper/ErrorPage";
import LoadingAnimation from "../../components/helper/Loading-animation";
import Table from "../../components/common/Table";
import { gracePeriodColumns } from "../../constant/Columns";
import ViewLoan from "../../components/views/ViewLoan";
import { formatDate } from "../../utils/dateFormat";

const GracePeriod = () => {

    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;
    const res_data = [];
    const { data, loading, error, reFetch } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/loans?status=grace-period&sort=-createdAt`, token);
    const [openModal, setOpenModal] = React.useState(false);
    const [status, setStatus] = React.useState();
    const [loanId, setLoanId] = React.useState();
    const [btnName, setBtnName] = React.useState();
    const [viewUserDet, setViewUserDet] = React.useState(false);
    const [debtCollection, setDebtCollection] = React.useState(false);

    if (loading) return <LoadingAnimation />

    if (error) return <ErrorPage message={ServerError} />

    const updatePayment = (id) => {
        setOpenModal(true)
        setStatus("verification")
        setLoanId(id)
        setBtnName("Verification")
    }

    const viewDetails = (id) => {
        setViewUserDet(true)
        setLoanId(id)
    }

    const debtCollectionFunc = (id) => {
        setDebtCollection(true)
        setLoanId(id)
    }

    const filterArr = () => {
        data?.forEach(res => {
            res_data.push({
                ...res,
                firstName: res?.user?.firstName,
                lastName: res?.user?.lastName,
                email: res?.user?.email,
                payment_Date: formatDate(res?.paymentDate),
                gracePeriodPaymentDate: formatDate(res?.gracePeriodPaymentDate),
                image: <img src={`${process.env.REACT_APP_IMG_API}${res?.user?.photoProfile}`} alt="" width={50} height={40} />,
                viewBtn: <button className="btn btn__table  color__blue" onClick={() => viewDetails(res?._id)}>  View </button>,
                verifyBtn: <button className="btn btn__table color__verify" onClick={() => updatePayment(res?._id)}>  Paid </button>,
                downloadBtn: <button className="btn btn__table color__download" onClick={() => debtCollectionFunc(res._id)}> Debt Collection  </button>,
            })
        });
    }
    filterArr();

    return (
        <React.Fragment>
            <div className="page-content px-5">
                <Breadcrumb default={LoanRequestRoute} defaultName="Approved Loan" title={"Loans"} />
                <MetaTag title_sco={LoanRequestPage} />

                <Container fluid>
                    <div className="page-title-box">
                        {loading ? <Loading /> : (<Table columns={gracePeriodColumns} data={res_data} />)}
                    </div>
                </Container>
            </div>

            {/* Open View modal */}
            <ModalComp
                ModalTitle=""
                open={viewUserDet}
                onClose={() => setViewUserDet(false)}
                cancel="close"
                Component={<ViewLoan onClose={() => setViewUserDet(false)} loan_Id={loanId} reFetch={reFetch} />}
            />

            {/* View Paid modal */}
            <SmallModal
                open={openModal}
                onClose={() => setOpenModal(false)}
                ModalTitle=""
                cancel="close"
                Components={<Modal
                    reFetch={reFetch}
                    onClose={() => setOpenModal(false)}
                    status={status}
                    loanId={loanId}
                    btnName={btnName}
                />}
            />

            <SmallModal
                open={debtCollection}
                onClose={() => setDebtCollection(false)}
                ModalTitle=""
                cancel="close"
                Components={<DebtCollection reFetch={reFetch} onClose={() => setDebtCollection(false)} status={status} loanId={loanId} btnName={btnName} />}
            />

        </React.Fragment>
    )
}
export default GracePeriod