import React from 'react';
import { Card, Container, CardBody } from "reactstrap";
import { Link } from 'react-router-dom';
import { useStore1Selector } from "index";
import { loginUser } from "../../Redux/Slices/userSlice";
import useFetch from "../../hooks/useFetch";
import { ImPointDown } from "react-icons/im";
import { BsArrowLeft } from 'react-icons/bs';
import { MandateUserRoute } from '../../constant/RouteName';
import LoadingAnimation from '../../components/helper/Loading-animation';
import Breadcrumb from '../../components/common/Breadcrumb';
import { DashboardPageDefault } from '../../constant/BreadCrum';
import ErrorPage from '../../components/helper/ErrorPage';
import { ServerError } from '../../constant/NotifyMessage';

const MandateLinks = () => {

    const userDet = useStore1Selector(loginUser);
    const token = userDet?.token;
    const userId = userDet?.data?.data?._id;
    const { data, loading, error } = useFetch(`${process.env.REACT_APP_BACKEND_URL}/mandates/status/${userId}?status=approved`, token);

    if (loading) return <LoadingAnimation />
    if (error) return <ErrorPage message={ServerError} />

    return (
        <React.Fragment>
            <div className="page-content">
                <Breadcrumb default={DashboardPageDefault} title="Mandate Links" />
                <Container fluid>
                    <div className="page-title-box">
                        <div className="mb-3">
                            <Link to={MandateUserRoute} > <BsArrowLeft /> Back </Link>
                        </div>
                        {
                            data?.map((link) =>
                                <Card key={link._id}>
                                    <CardBody>
                                        <h5> Click below <ImPointDown /> </h5>
                                        <Link to={`/application/${link._id}`}> {`https://app.ritikefinance.com/application/${link._id}`} </Link>
                                    </CardBody>
                                </Card>
                            )
                        }
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default MandateLinks