//* Costs
const costColumns = [
    { label: "Cost Number", field: "costNumber", sort: "asc", width: 150 },
    { label: "Cost Name", field: "name", sort: "asc", width: 150 },
    { label: "Amount", field: "amount", sort: "asc", width: 150 },
    { label: "Date Created", field: "dateCreated", sort: "asc", width: 150 },
    { label: "Action", field: "viewBtn", sort: "asc", width: 150 },
];

//* Action Required
const actionRequiredColumns = [
    { label: "First Name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last Name", field: "lastName", sort: "asc", width: 150 },
    { label: "Loan Amount", field: "amount", sort: "asc", width: 150 },
    { label: "Duration", field: "duration", sort: "asc", width: 150 },
    { label: "Payment Date", field: "payment_Date", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Update", field: "verifyBtn", sort: "asc", width: 150 },
    { label: "Send a Mandate", field: "mandateBtn", sort: "asc", width: 150 },
    { label: "Grace Period", field: "gracePeriodBtn", sort: "asc", width: 150 },
    { label: "Debt Collection", field: "downloadBtn", sort: "asc", width: 150 },
];

//*Grace Period
const gracePeriodColumns = [
    { label: "First Name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last Name", field: "lastName", sort: "asc", width: 150 },
    { label: "Email", field: "email", sort: "asc", width: 150 },
    { label: "Loan Amount", field: "amount", sort: "asc", width: 150 },
    { label: "Duration", field: "duration", sort: "asc", width: 150 },
    { label: "Payment Date", field: "payment_Date", sort: "asc", width: 150 },
    { label: "Grace Period Amount", field: "gracePeriodAmount", sort: "asc", width: 150 },
    { label: "Grace Period Percentage", field: "gracePeriodPercentage", sort: "asc", width: 150 },
    { label: "Grace Period Payment date", field: "gracePeriodPaymentDate", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Update", field: "verifyBtn", sort: "asc", width: 150 },
    { label: "Debt Collection", field: "downloadBtn", sort: "asc", width: 150 },
];


//* Debt collections
const debCollectionColumns = [
    { label: "First Name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last Name", field: "lastName", sort: "asc", width: 150 },
    {
        label: "Debt starting date",
        field: "debt_start_date",
        sort: "asc",
        width: 150,
    },
    {
        label: "Debt weekly Duration",
        field: "debt_weekly_duration",
        sort: "asc",
        width: 150,
    },
    { label: "View details", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Update ", field: "verifyBtn", sort: "asc", width: 150 },
    { label: "Send to Management ", field: "mngtBtn", sort: "asc", width: 150 },
];

//* Mandate 
const mandateColumns = [
    { label: "No", field: "mandateNumber", sort: "asc", width: 150 },
    { label: "First Name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last Name", field: "lastName", sort: "asc", width: 150 },
    { label: "Email", field: "email", sort: "asc", width: 150 },
    { label: "Grace Period Amount", field: "gracePeriodAmount", sort: "asc", width: 150 },
    { label: "Grace Period Percentage", field: "gracePeriodPercentage", sort: "asc", width: 150 },
    { label: "Grace Period Payment Date", field: "gracePeriodPaymentDate", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
];

//* Pre- agreement 
const preAgreementColumns = [
    // { label: "No", field: "mandateNumber", sort: "asc", width: 150 },
    { label: "First Name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last Name", field: "lastName", sort: "asc", width: 150 },
    { label: "Email", field: "email", sort: "asc", width: 150 },
    // { label: "Grace Period Amount", field: "gracePeriodAmount", sort: "asc", width: 150 },
    // { label: "Grace Period Percentage", field: "gracePeriodPercentage", sort: "asc", width: 150 },
    { label: "Grace Period Payment Date", field: "gracePeriodPaymentDate", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
];

//* Teams
const teamColumns = [
    { label: "First Name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last Name", field: "lastName", sort: "asc", width: 150 },
    { label: "Email", field: "email", sort: "asc", width: 150 },
    { label: "Gender", field: "gender", sort: "asc", width: 150 },
    { label: "Phone number", field: "phoneNumber", sort: "asc", width: 150 },
    { label: "Action", field: "actionBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
];

//* Users
const customerColumns = [
    { label: "Firs Name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last Name", field: "lastName", sort: "asc", width: 150 },
    { label: "Email", field: "email", sort: "asc", width: 150 },
    { label: "Gender", field: "gender", sort: "asc", width: 150 },
    { label: "Phone number", field: "phoneNumber", sort: "asc", width: 150 },
    { label: "Verified", field: "verify", sort: "asc", width: 150 },
    { label: "Status", field: "active", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Action", field: "actionBtn", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
];

//* Dashboard
const dashboardColumns = [
    { label: "First Name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last Name", field: "lastName", sort: "asc", width: 150 },
    { label: "Duration", field: "duration", sort: "asc", width: 150 },
    { label: "Paid date", field: "payment_Date", sort: "asc", width: 150 },
    { label: "Loan Amount", field: "amount", sort: "asc", width: 150 },
    { label: "Total return", field: "totalAmount", sort: "asc", width: 150 },
    { label: "Status", field: "btnStatus", sort: "asc", width: 150 },
    { label: "Completion", field: "progressBar", sort: "asc", width: 150 },
    { label: "Delete", field: "deleteBtn", sort: "asc", width: 150 },
];

//* Loan request
const loanRequestColumns = [
    { label: "First Name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last name", field: "lastName", sort: "asc", width: 150 },
    { label: "Loan Amount", field: "amount", sort: "asc", width: 150 },
    { label: "Duration", field: "duration", sort: "asc", width: 150 },
    { label: "Payment Date", field: "payment_Date", sort: "asc", width: 150 },
    { label: "View", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Verify", field: "verifyBtn", sort: "asc", width: 150 },
    { label: "Send form", field: "downloadBtn", sort: "asc", width: 150 },
    { label: "Approved", field: "approveBtn", sort: "asc", width: 150 },
    { label: "Declined", field: "declineBtn", sort: "asc", width: 150 },
];

//* approve loans 
const approvedLoansColumns = [
    { label: "First Name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last Name", field: "lastName", sort: "asc", width: 150 },
    { label: "Loan Amount", field: "amount", sort: "asc", width: 150 },
    // { label: "Grace Period Amount", field: "grace_period", sort: "asc", width: 150 },
    // { label: "Grace Period Amount", field: "gracePeriodAmount", sort: "asc", width: 150 },
    // { label: "Grace Period Percentage", field: "gracePeriodPercentage", sort: "asc", width: 150 },
    { label: "Duration", field: "duration", sort: "asc", width: 150 },
    { label: "Payment Date", field: "payment_Date", sort: "asc", width: 150 },
    { label: "Action", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Action", field: "paidBtn", sort: "asc", width: 150 },
];

//* paid loans 
const paidLoansColumns = [
    { label: "First Name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last Name", field: "lastName", sort: "asc", width: 150 },
    { label: "Loan Amount", field: "amount", sort: "asc", width: 150 },
    { label: "Grace Period Amount", field: "gracePeriodAmount", sort: "asc", width: 150 },
    { label: "Grace Period Percentage", field: "gracePeriodPercentage", sort: "asc", width: 150 },
    { label: "Grace Period Payment Date", field: "gracePeriodPeriodPaymentDate", sort: "asc", width: 150 },
    { label: "Duration", field: "duration", sort: "asc", width: 150 },
    { label: "Payment Date", field: "payment_Date", sort: "asc", width: 150 },
    { label: "Action", field: "viewBtn", sort: "asc", width: 150 },
];


//* Management debt collection
const managementDebtColumns = [
    { label: "First Name", field: "firstName", sort: "asc", width: 150 },
    { label: "Last Name", field: "lastName", sort: "asc", width: 150 },
    {
        label: "Payment date",
        field: "payment_Date",
        sort: "asc",
        width: 150,
    },
    {
        label: "Debt collector starting date",
        field: "debt_start_date",
        sort: "asc",
        width: 150,
    },
    { label: "View details", field: "viewBtn", sort: "asc", width: 150 },
    { label: "Update ", field: "verifyBtn", sort: "asc", width: 150 },
    { label: "BlackList ", field: "blacklistBtn", sort: "asc", width: 150 },

];

export {
    actionRequiredColumns, approvedLoansColumns, costColumns, customerColumns,
    dashboardColumns, debCollectionColumns, gracePeriodColumns, loanRequestColumns, managementDebtColumns, mandateColumns, paidLoansColumns, preAgreementColumns, teamColumns
};

