import React from "react";
import { Col, Container, Row } from "reactstrap";
import Breadcrumb from "../../components/common/Breadcrumb";
import MetaTag from "../../components/common/MetaTagSeo";
import { LoanRequestPage } from "../../constant/SCO_Name";
import { FinancialStatmentMngt } from "../../constant/RouteName";
import useFetch from "../../hooks/useFetch";
import { useStore1Selector } from "index";
import { loginUser } from "../../Redux/Slices/userSlice";

import MonthlyLoanedOut from "../../sections/Management/financial-statement/MonthlyLoanedOut";
import MonthlyRepaid from "../../sections/Management/financial-statement/MonthlyRepaid";
import Budgets from "../../sections/Management/financial-statement/Budgets";
import Report90days from "../../sections/Management/financial-statement/Report90days";


const FinancialStatement = () => {
  const userDet = useStore1Selector(loginUser);
  const token = userDet?.token;

  const { data: loanedOutData, } = useFetch(
    `${process.env.REACT_APP_BACKEND_URL}/financial-report?status=loaned_out&sort=-createdAt`,
    token
  );

  const { data: paidData, } = useFetch(
    `${process.env.REACT_APP_BACKEND_URL}/financial-report?status=repaid&sort=-createdAt`,
    token
  );

  const { data: totalLoanedOut } = useFetch(
    `${process.env.REACT_APP_BACKEND_URL}/financial-report/loaned-out`,
    token
  );

  const { data: totalLoanedOutCurrentMonth } = useFetch(
    `${process.env.REACT_APP_BACKEND_URL}/financial-report/loaned-out/approved`,
    token
  );

  const { data: totalCredit } = useFetch(
    `${process.env.REACT_APP_BACKEND_URL}/credits/total-credit`,
    token
  );

  return (
    <React.Fragment>
      <div className="page-content px-5">
        <Breadcrumb
          default={FinancialStatmentMngt}
          defaultName="Financial Statement"
          title={"financial statement"}
        />
        <MetaTag title_sco={LoanRequestPage} />

        <Container fluid>
          <Row >
            <Col xs={12} sm={12} md={12} lg={12}>
              <MonthlyLoanedOut data={loanedOutData} />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <MonthlyRepaid data={paidData} />
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Budgets totalLoanedOut={totalLoanedOutCurrentMonth} totalCredit={totalCredit} />
            </Col>
            <Col xs={12} sm={12} md={6} lg={6}>
              <Report90days loanedOutReport={totalLoanedOut?.loanedOutReport} repaidReport={totalLoanedOut?.repaidReport} />
            </Col>
          </Row>
        </Container>
      </div>

    </React.Fragment>
  );
};
export default FinancialStatement;
